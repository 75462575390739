import React, { useState, useEffect, useCallback } from "react";
import { Menu, Icon, Dropdown, Search, Popup, Grid, Header } from "semantic-ui-react";
import { OnHoverOnClick, Notifications, Button, SelectionsModal, DYLAvatar } from "dyl-components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import authActions from "actions/auth/auth";
import imageActions from 'actions/image';
import appActions from 'actions/app/app';
import notificationsActions from "actions/notifications";

import MasterAccountForm from 'shared/MasterAccountForm';
import AddContactModal from 'shared/modals/AddContactModal';
import AddLeadModal from "shared/modals/AddLeadModal";
import AddOpportunityModal from "shared/modals/AddOpportunityModal";
import AddAccountModal from "shared/modals/AddAccountModal";
import SendEmailModal from "shared/modals/SendEmailModal";
import AddTaskModal from "shared/modals/AddTaskModal";
import AddEventModal from "shared/modals/AddEventModal";
import NotificationPopupModals from "shared/modals/NotificationPopUpModals";
import { withRouter } from 'shared/withRouter';

import { TZDisplay } from "utils";
import "./index.scss";

const modalCategories = [
    {
        header: 'Modules',
        selections: [
            { key: 'Contact', text: 'Contact', icon: 'fa-solid fa-address-book' },
            { key: 'Lead', text: 'Lead', icon: 'user' },
            { key: 'Opportunity', text: 'Opportunity', icon: 'fas fa-funnel-dollar' }
        ]
    },
    {
        header: 'Records',
        selections: [
            { key: 'MasterAccount', text: 'Master Account', icon: 'fa-solid fa-buildings' },
            { key: 'BusinessAccount', text: 'Business Account', icon: 'fa-solid fa-building' },
            { key: 'HouseholdAccount', text: 'Household Account', icon: 'fa-solid fa-house-chimney-blank' }
        ]
    }
];

//TODO: move onOpen to the AddContactModal and AddBusinessModal
const AddModuleRecordsModalContent = ({ activeContent, onClose }) => {
    const isAddingModule = useSelector(state => state.contacts.isCreating || state.pipeline.isConvertingContact || state.account.isLinkingContactsToAccount || state.contact_sequence.isHandlingConvert || state.sequences.isAddingContactToSequence || state.sequence_tasks.isReadingSequenceContactTasks)
    switch (activeContent) {
        case 'MasterAccount':
            return <MasterAccountForm close={onClose} />;
        case 'Contact':
            return <AddContactModal onOpen={() => { return Promise.resolve(); }} onClose={onClose} />;
        case 'BusinessAccount':
            return <AddAccountModal account_type={'business'} onClose={onClose} />;
        case 'HouseholdAccount':
            return <AddAccountModal account_type={'household'} onClose={onClose} />;
        case 'Lead':
            return <AddLeadModal onClose={() => {
                if (!isAddingModule) {
                    onClose();
                }
            }} />;
        case 'Opportunity':
            return <AddOpportunityModal onClose={() => {
                if (!isAddingModule) {
                    onClose();
                }
            }} />;
        default:
            return null;
    }
}
const TopNavigation = ({isSidebarOpen, onToggleMainMenu}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { name, email, profilePicture, user_id, isReadingUser, centerNotifications, isReadingNotificationCenter, hasNotifications } = useSelector((state) => {  
        return {
            name: state.auth.name,
            email: state.users.userProfile.email,
            profilePicture: state.image.image ? `data:image/png;base64,${state.image.image}` : '',
            user_id: state.auth.user_id,
            isReadingUser: state.auth.isAuthenticatingUser || state.users.isReadingUser,
            centerNotifications: state.notifications.centerNotifications,
            isReadingNotificationCenter: state.notifications.isReadingNotificationCenter,
            hasNotifications: state.notifications.hasNotifications,
        }
    });
    const [isModulesModalOpen, setIsModulesModalOpen] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const [isNotficationsOpen, setIsNotficationsOpen] = useState(false);

    const showModal = () => {
        setIsModulesModalOpen(true);
    }
    
    const closeModal = () => {
        setIsModulesModalOpen(false);
    }
    
    const onRecordCurrentRoute = () => {
        dispatch(appActions.onSaveSettingsRoute(location.pathname));
    }
    
    const onToggleNotfications = () => {
        setIsNotficationsOpen(prevState => (!prevState));
    } 
    
    const onLogOut = () => {
        dispatch(authActions.logout());
    }
    const {
        onToggleModal,
        taskModalOpen,
        setTaskModalOpen,
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        handleSubmitEvent,
        resetField,
        taskControl,
        taskWatch,
        taskIsValid,
        taskIsDirty,
        taskTrigger,
        taskSetValue,
        taskGetValues,
        taskClearErrors,
        taskSetError,
        taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDeleteTask,
        onUpdateTask,
        handleSubmit,
        task_labels,
        organizer_id,
        organizer,
        organizer_email,
        eventModalOpen,
        emailModalOpen,
        emailData,
        person_id,
        onDelete,
        onUpdate,
        onClearAllNotifications,
        onClearNotification,
        setEventModalOpen,
        onOpenNotification,
        current_user
    } = NotificationPopupModals();

    useEffect(() => {
        dispatch(imageActions.getUserProfileImage({ isThumbnail: true, user: user_id }))
            .then(() =>{
                setProfilePic({profilePic: profilePicture});
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if(centerNotifications.length === 0){
            dispatch(notificationsActions.hasReadNotifications());
        }
    }, [dispatch, centerNotifications]);

    const readNotificationCenter = useCallback(() => {
        return dispatch(notificationsActions.onReadNotificationCenter());
    }, [dispatch]);

    const isNotificationsButtonsDisabled = (isReadingNotificationCenter || centerNotifications.length === 0);
    
    return (
        <React.Fragment>
            <Menu className={`DYL2MenuTop ${isSidebarOpen ? 'DYL2MenuTop--squished' : 'DYL2MenuTop--full'}`} fixed={"top"} secondary>
                <Menu.Item>
                    <Popup
                        trigger={
                            <Icon
                                circular
                                className='MenuTop__Chevron'
                                name={
                                    isSidebarOpen
                                        ? "chevron left"
                                        : "chevron right"
                                }
                                link
                                onClick={onToggleMainMenu}
                            />
                        }
                        content={
                            isSidebarOpen
                                ? "Close menu"
                                : "Open menu"
                        }
                        position="right center"
                        inverted
                    />
                </Menu.Item>
                <Menu.Item>
                    <Popup
                        trigger={
                            <Icon
                                inverted
                                className="fas fa-plus-circle"
                                link
                                onClick={showModal}
                                size='big'
                            />
                        }
                        content={"Add New"}
                        position="right center"
                        inverted
                    />
                </Menu.Item>
                <Menu.Menu
                    className={`DYL2MenuTopRight--${isSidebarOpen ? "squished" : "full"
                        }`}
                    position="right"
                >
                    <Menu.Item className="DYL2MenuItemTopRight">
                        {!isReadingUser &&
                            <>
                                Hello, {name}! &nbsp; <TZDisplay />
                            </>
                        }
                    </Menu.Item>
                    <Menu.Item>
                        <Search
                            placeholder="Search Everything"
                            className="TopNavigation__search"
                            fluid
                            //TODO: turn on and configure this AFTER the sitewide search is implemented 
                            // loading={}
                            // onSearchChange={}
                            // results={}
                            // resultRenderer={}
                            // value={}
                            // onResultSelect={}
                            disabled //TODO
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <OnHoverOnClick
                            inverted 
                            className="TopNavigation__Notifications"
                            pinned
                            position={isNotficationsOpen ? 'bottom center' : 'left center'}
                            trigger={
                                <>
                                    {hasNotifications &&
                                        <div className="TopNavigation__Notifications--Dot" />
                                    }
                                    <Icon name="bell" size="large" inverted style={{ cursor: 'pointer' }}/>
                                </>
                            }
                            hoverContent='Notifications'
                            invertedHover
                            basic={false}
                            clickContent={ 
                                <div style={{padding: 10, width: 450}}>
                                    <Grid>
                                        <Grid.Column width='4'><Header as='h3'>Notifications</Header></Grid.Column>
                                        <Grid.Column width='6' textAlign='right' style={{paddingLeft:0, paddingRight:0}}><Button basic as='a' href='/notifications'>View All</Button></Grid.Column>
                                        <Grid.Column width='4' textAlign='right' style={{paddingLeft:0, paddingRight:0}}><Button disabled={isNotificationsButtonsDisabled} as='on' onClick={()=> onClearAllNotifications()}>Clear All</Button></Grid.Column>
                                        <Grid.Column verticalAlign='middle'><Icon className="fa-solid fa-xmark" size='large' onClick={()=> onToggleNotfications()} style={{ cursor: 'pointer' }}/></Grid.Column>
                                    </Grid>
                                    <Notifications
                                        notifications={centerNotifications} 
                                        onOpen={onOpenNotification}
                                        onClear={onClearNotification}
                                        loading={isReadingNotificationCenter}
                                    />
                                </div>
                            }
                            open={isNotficationsOpen}
                            onClick={
                                () => { 
                                    onToggleNotfications();
                                    readNotificationCenter(); 
                                }
                            } 
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Dropdown
                            inline
                            text={
                                <Popup
                                    trigger={<Icon
                                        name="question circle"
                                        size="large"
                                        inverted
                                    />}
                                    inverted
                                    content='Help'
                                    position='left center'
                                />
                            }
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    content='Help'
                                    href='https://dyl-cloud.atlassian.net/servicedesk/customer/portals'
                                    target='_blank'
                                />
                                <Dropdown.Item
                                    content='Support'
                                    href='https://dyl.com/support'
                                    target='_blank'
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                    <Menu.Item>
                        <Dropdown
                            inline
                            text={
                                <DYLAvatar name={name} email={email} picture={profilePic} position='left center'/>
                            }
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    content="Profile"
                                    as={Link}
                                    to={`/settings/users/${user_id}/general`}
                                />
                                <Dropdown.Item content="Billing" />
                                <Dropdown.Item
                                    content="Logout"
                                    onClick={onLogOut}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                    <Menu.Item
                        icon={(
                            <Popup
                                trigger={<Icon
                                    name="cog"
                                    size="large"
                                    inverted
                                />}
                                inverted
                                content='Settings'
                                position='left center'
                            />
                        )}
                        as={Link}
                        to="/settings/general"
                        link
                        onClick={onRecordCurrentRoute}
                    />
                </Menu.Menu>
            </Menu>
            <SelectionsModal
                selectionHeader='Add New'
                categories={modalCategories}
                ToRender={AddModuleRecordsModalContent}
                contentModalSize={activeContent => {
                    if (activeContent === 'selection') {
                        return 'tiny'
                    }
                    if (activeContent === 'MasterAccount') {
                        return 'small';
                    }
                    return null;
                }}
                open={isModulesModalOpen}
                close={closeModal}
            />
            <SendEmailModal
                open={emailModalOpen}
                onClose={() => { if(emailModalOpen){onToggleModal("email", 0)} }}
                contact_id={person_id}
            />
            <AddTaskModal
                open={taskModalOpen}
                onClose={() => setTaskModalOpen(false)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={() => setTaskModalOpen(false)}
                email={emailData} 
            />
            <AddEventModal
                open={eventModalOpen}
                onClose={() => setEventModalOpen(false)}
                onEdit={handleSubmitEvent(onUpdate)}
                onDeleteEvent={onDelete}
                eventBeingEdited={eventBeingEdited}
                selected_users={[current_user]}
                isAllowedToModify={isAllowedToModify}
                control={control}
                watch={watch}
                isValid={isValid}
                isDirty={isDirty}
                trigger={trigger}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
            />
        </React.Fragment>
    );
};

export default (withRouter(TopNavigation));

