//import React, { useEffect, useState } from 'react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
//import { useDispatch, useSelector } from 'react-redux';
//import provisionActions from 'actions/provision';
import {Phone} from 'softphone/softphone.js';

export const Softphone = () => {
    //const dispatch = useDispatch();
    let softphone = useRef({});

    const { creds, isReadingSoftphones } = useSelector((state) => {  
        return {
            creds: state.officeView.creds,
            isReadingSoftphones: state.officeView.isReadingSoftphones
        }
    });

    useEffect(() => {
        if (creds.username && ! softphone?.phone?.config ) {
            softphone.current = Phone(creds,{}, {})
        }

    }, [isReadingSoftphones, creds]);

    return softphone;
}
