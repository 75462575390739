import React, { useState } from 'react';
import { Grid, Icon, Label, Popup, Button } from 'semantic-ui-react';
import './index.scss';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CustomGroupUtils, StringUtils } from 'utils';
import contactActions from 'actions/contact';

import { STATUS_TYPES, Notification, ClippedContent } from 'dyl-components';
import LocationsForm from 'shared/forms/LocationsForm';
import accountActions from 'actions/account';
import PhonesField from './PhonesField';
import EmailsField from './EmailsField';
import { useParams } from 'react-router-dom';
import LocationsView from 'shared/LocationsView';

const ContactInformation = ({
    custom_data,
    mainLocations,
    contact,
    isLocationOnEditMode,
    onConfirmEditLocation,
    onCancelEditLocation,
    onChangeLocation,
    tmpLocation,
    isUpdatingLocation,

    location_type,
}) => {
    const { contact_id } = useParams();

    const hasSecondRowLocation = (location) => {
        return location.additional_street || location.street ? true : false;
    }

    const dispatch = useDispatch();

    const loading = useSelector(state => {
        const { isUpdatingContact, isReadingContact } = state.contact;
        return isUpdatingContact || isReadingContact;
    })

    const [fieldBeingEdited, setFieldBeingEdited] = useState(null);

    return (
        <Grid columns='equal' className='ContactInfo'>
            <PhonesField isEditing={fieldBeingEdited === "phones"} onCancelEdit={(cb) => {setFieldBeingEdited(null); cb();}} onEdit={() => {setFieldBeingEdited("phones")}} allowNoPhones contact_id={contact_id} />
            <EmailsField isEditing={fieldBeingEdited === "emails"} onCancelEdit={(cb) => {setFieldBeingEdited(null); cb();}} onEdit={() => {setFieldBeingEdited("emails")}} allowNoEmails contact_id={contact_id} />

            {!isLocationOnEditMode &&
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                        <Icon className='far fa-location-dot' color='grey' />
                        <Popup
                            trigger={<Label className='ContactInfo__locationLabel'>
                                {mainLocations.length <= 0 && 'No default location'}
                                {mainLocations.map((mainLocation, index) => (
                                    <div className='ContactInfo__locationContainer'>
                                        <span key={index} className='ContactInfo__locationSpan' style={{ overflowWrap: 'break-word', maxWidth: '85%' }}>
                                            <span className='ContactInfo__contact--address'>
                                                <div className={`ContactInfo__locationFirstRow${hasSecondRowLocation(mainLocation) ? '--secondRow' : '--noSecondRow'}`}>
                                                    {mainLocation.street ? `${mainLocation.street} ` : ''}
                                                    {mainLocation.additional_street ? `${mainLocation.additional_street} ` : ''}
                                                </div>
                                                <div>
                                                    {mainLocation.city ? `${mainLocation.city}, ` : ''}
                                                    {mainLocation.state ? `${mainLocation.state} ` : ''}
                                                    {mainLocation.zip ? mainLocation.zip : ''}
                                                </div>
                                            </span>
                                        </span>
                                        <div className='ContactInfo__caretContainer'>
                                            <Icon name='fa-solid fa-caret-down' size='small' className='ContactInfo__caretDown' />
                                        </div>
                                    </div>
                                    // <span key={index}>
                                    //     <span className='ContactInfo__contact' style={{display: 'flex'}}>
                                    //         <p style={{margin: 0}}>{LocationUtils.getAddressLabel(mainLocation.label)} <Icon name='caret down' /></p> 
                                    //     </span>
                                    // </span>
                                ))}
                            </Label>}
                            content={(
                                <LocationsView
                                    id={contact_id}
                                    loading={loading}
                                    location_type={'home'}
                                    mainLocationId={mainLocations[0]?.id}
                                    refresh={() => {
                                        return dispatch(contactActions.readContact(contact_id))
                                    }}
                                    type={'contact'}
                                />
                            )}
                            on='click'
                            size='tiny'
                            style={{ width: '100em', padding: 0 }}
                            position='bottom center'
                        />
                    </Grid.Column>
                </Grid.Row>}
            {isLocationOnEditMode &&
                <React.Fragment>
                    <Grid.Row>
                        <Grid.Column>
                            <LocationsForm
                                location_type={location_type}
                                locations={tmpLocation}
                                onChange={onChangeLocation}
                                onInlineEditPrimary
                                onToggleMain={() => { }} />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Button primary size='tiny' floated='right' loading={isUpdatingLocation} onClick={onConfirmEditLocation}>
                                Confirm
                            </Button>

                            {!isUpdatingLocation &&
                                <Button basic size='tiny' floated='right' color='red' onClick={onCancelEditLocation}>
                                    Cancel
                                </Button>}
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>}
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <Icon className='fas fa-globe' />
                    <span>
                        {contact.website ? <a href={StringUtils.formatLink(contact.website)} rel="noreferrer" target="_blank">{contact.website}</a> : 'None'}
                    </span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={'equal'}>
                <Grid.Column>
                    <ClippedContent>
                        School
                    </ClippedContent>
                </Grid.Column>
                <Grid.Column>
                    {custom_data?.fields?.school || 'None'}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={'equal'}>
                <Grid.Column>
                    <ClippedContent>
                        Degree
                    </ClippedContent>
                </Grid.Column>
                <Grid.Column>
                    {custom_data?.fields?.degree || 'None'}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ClippedContent>
                        Birthday
                    </ClippedContent>
                </Grid.Column>
                <Grid.Column>
                    <ClippedContent>
                        {contact.date_of_birth}
                    </ClippedContent>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

class ContactInfoContainer extends React.Component {
    state = {
        isLocationOnEditMode: false,
        tmpLocation: []
    }

    onEditLocation = () => {
        this.setState({ isLocationOnEditMode: true, tmpLocation: [...this.props.mainLocations] })
    }

    onChangeLocation = (_, { name, value, id }) => {
        const { tmpLocation } = this.state;

        tmpLocation[0][name] = value;

        this.setState({
            tmpLocation
        })
    }

    onConfirmEditDateOfBirth = ({ value }) => {
        const { contact, custom_data, contact_id, account_id } = this.props;
        const { first_name, last_name, suffix, contact_type, relationship } = contact;

        let primary_location = this.props.mainLocations[0];
        let primary_location_id = primary_location ? primary_location.id : 0;

        return this.props.onUpdateContact(contact_id, {
            custom_data,
            first_name,
            last_name,
            suffix,
            ...(contact_type === 'person' ? { date_of_birth: value } : {}),
            contact_type,
            primary_location_id,
            relationship
        }, account_id).then(() => {
            Notification.alert('Successfully updated contact!', STATUS_TYPES.SUCCESS, true);
            this.props.onReadContactInformation(contact_id, account_id);
        })
    }

    onUpdateSchoolOrDegree = ({ name, value }) => {
        const { contact, custom_data, contact_id, account_id } = this.props;
        const { first_name, last_name, suffix, contact_type, relationship } = contact;

        let primary_location = this.props.mainLocations[0];
        let primary_location_id = primary_location ? primary_location.id : 0;

        return this.props.onUpdateContact(contact_id, {
            custom_data: { ...custom_data, fields: { ...custom_data.fields, [name]: value } },
            first_name,
            last_name,
            suffix,
            ...(contact_type === 'person' ? { date_of_birth: contact.date_of_birth } : {}),
            contact_type,
            primary_location_id,
            relationship
        }, account_id).then(() => {
            Notification.alert('Successfully updated contact!', STATUS_TYPES.SUCCESS, true);
            this.props.onReadContactInformation(contact_id, account_id);
        })
    }

    onConfirmEditLocation = () => {
        const { contact_id, queryParameters, account_id } = this.props;

        return this.props.onUpdateLocation(this.state.tmpLocation[0], queryParameters).then(() => {
            this.setState({ isLocationOnEditMode: false })
            this.props.onReadContactInformation(contact_id, account_id);
        })
    }

    onCancelEditLocation = () => {
        this.setState({ isLocationOnEditMode: false })
    }

    render() {
        return (
            <ContactInformation
                {...this.props}
                {...this.state}
                onEditLocation={this.onEditLocation}
                onConfirmEditLocation={this.onConfirmEditLocation}
                onCancelEditLocation={this.onCancelEditLocation}
                onConfirmEditDateOfBirth={this.onConfirmEditDateOfBirth}
                onChangeLocation={this.onChangeLocation}
                onUpdateSchoolOrDegree={this.onUpdateSchoolOrDegree}
                location_type={'home'}
                fields={this.props.fields}
            />
        )
    }
}

const mapStateToProps = state => {
    const locations = state.contact.contact?.locations.slice(0) || [];
    const mainLocations = locations.filter(location => location.main);
    const custom_data = CustomGroupUtils.groupAndFlatten(state.contact.custom_data);
    return {
        contact: state.contact.contact,
        location: state.contact.contact?.locations[0],
        contact_id: Number(state.contact.contact_id),
        isUpdatingLocation: state.account.isUpdatingLocation,
        custom_data,
        account_id: Number(state.contact.account_id),
        queryParameters: { ...state.contact.queryParameters, account_id: Number(state.contact.account_id) },
        mainLocations,
        fields: state.contact.custom_data.fields || [],
        isReadingContact: state.contact.isReadingContact || state.account.isReadingProfile
    }
};

const mapDispatchToProps = (dispatch) => ({
    onUpdateContact: (contact_id, updatedContactDetails) => {
        return dispatch(contactActions.updateContact(contact_id, updatedContactDetails));
    },
    onUpdateLocation: (locationToUpdate, queryParameters) => {
        let { id, ...otherProps } = locationToUpdate;
        return dispatch(accountActions.updateLocation(id, { ...otherProps }, queryParameters, id));
    },
    onReadContactInformation: (contact_id) => {
        dispatch(contactActions.readContact(contact_id));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoContainer);
