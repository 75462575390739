import React from "react";
import { Icon, Table, TimestampFilters } from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";

import { useSelector } from "react-redux";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import {
    KEY_ACCOUNT_CO_OWNER,
    KEY_ACCOUNT_NAME,
    KEY_ACCOUNT_OPPORTUNITY_STATUS,
    KEY_ACCOUNT_OWNER,
    KEY_CONTACT_CO_OWNER,
    KEY_CONTACT_NAME,
    KEY_CONTACT_OWNER,
    KEY_CREATED,
    KEY_CREATED_BY,
    KEY_EXPIRES_ON,
    KEY_LAST_MODIFIED,
    KEY_LAST_MODIFIED_BY,
    KEY_NUMBER_PRODUCTS_BUNDLES,
    KEY_OPPORTUNITY_NAME,
    KEY_PRODUCTS_BUNDLES,
    KEY_PROPOSAL_INVOICE_CREATED,
    KEY_STATUS,
    KEY_SUBTOTAL,
} from "shared/constants/QUOTES_ORDERS_COLUMNS";

const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

export const QuotesAndOrdersHeader = ({ isColumnHidden }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const createdOrder =
        params.get("order_by") === "created" && params.get("order") === "asc";
    const lastModifiedOrder =
        params.get("order_by") === "activity" && params.get("order") === "asc";
    const proposalInvoiceCreatedOrder =
        params.get("order_by") === "proposal_invoice_created" &&
        params.get("order") === "asc";
    const expiresOnOrder =
        params.get("order_by") === "expires_on" &&
        params.get("order") === "asc";

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = params.get("order") !== "asc" ? "asc" : "desc";
        query.set("order", order);
        const query_string = query.toString();
        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ""}`);
    };

    const lastModifiedOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "activity");
        const query_string = query.toString();
        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ""}`);
    };

    const proposalInvoiceCreatedOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "proposal_invoice_created");
        const query_string = query.toString();
        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ""}`);
    };

    const expiresOnOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        query.set("order_by", "expires_on");
        const query_string = query.toString();
        navigate(`/settings/quotes-orders${query_string ? `?${query_string}` : ""}`);
    };

    const { count, quotesAndOrders = [] } = useSelector((state) => {
        return {
            count: 0,
            quotesAndOrders: [],
        };
    });

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
                className="QuotesAndOrdersTable__SmallFixedWidth"
            >
                <SelectAllCheckbox
                    count={count}
                    data={quotesAndOrders}
                    type={"quotes and orders"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_CREATED) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <span style={{ marginRight: 5 }}>Created</span>
                    <TimestampFilters
                        timestamp={timestamp}
                        onToggleTimeUnit={(timestamp) =>
                            onToggleTimeUnit(
                                timestamp,
                                setTimestamp,
                                params,
                                navigate,
                                "/settings/quotes-orders"
                            )
                        }
                        isCustomDateRangeOpen={isCustomDateRangeOpen}
                        onOpenCustomDateRange={() => {
                            setCustomDateRangeOpen(true);
                        }}
                        onCloseCustomDateRange={() => {
                            setCustomDateRangeOpen(false);
                        }}
                        dateRange={dateRange}
                        onDateRangeChange={onDateRangeChange}
                        getData={() =>
                            getData({
                                setCustomDateRangeOpen,
                                dateRange,
                                userAuthTimezone,
                                params,
                                navigate,
                                url: "/settings/quotes-orders",
                            })
                        }
                    />

                    <SortActions
                        direction={createdOrder}
                        onToggleSort={() => {
                            createdOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Created By
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Last Modified
                    <SortActions
                        direction={lastModifiedOrder}
                        onToggleSort={() => {
                            lastModifiedOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Last Modified By
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_PROPOSAL_INVOICE_CREATED) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Proposal/Invoice Created
                    <SortActions
                        direction={proposalInvoiceCreatedOrder}
                        onToggleSort={() => {
                            proposalInvoiceCreatedOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_EXPIRES_ON) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Expires On
                    <SortActions
                        direction={expiresOnOrder}
                        onToggleSort={() => {
                            expiresOnOrderFilter();
                        }}
                    />
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell
                className="QuotesAndOrdersTable__MediumFixedWidth"
                width={2}
            >
                ID
            </SemanticTable.HeaderCell>
            {!isColumnHidden(KEY_NUMBER_PRODUCTS_BUNDLES) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    # Product/Bundles
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_PRODUCTS_BUNDLES) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    # Product/Bundles
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CONTACT_OWNER) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Contact Owner
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CONTACT_CO_OWNER) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Contact Co-Owner
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_OWNER) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Account Owner
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_CO_OWNER) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Account Co-Owner
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_CONTACT_NAME) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__LargeFixedWidth"
                    width={4}
                >
                    Contact Name
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_NAME) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__LargeFixedWidth"
                    width={4}
                >
                    Account Name
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_OPPORTUNITY_NAME) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    Name
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_OPPORTUNITY_STATUS) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    Account/Opportunity Status
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_SUBTOTAL) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    Subtotal
                </SemanticTable.HeaderCell>
            )}
            {!isColumnHidden(KEY_STATUS) && (
                <SemanticTable.HeaderCell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    Status
                </SemanticTable.HeaderCell>
            )}
        </Table.Row>
    );
};
