
import React, { useState } from 'react';
import { Link, NavLink, Outlet, Navigate } from 'react-router-dom';
import { Sidebar, Menu, Segment, Header, Icon, Image, Dimmer, Loader } from 'semantic-ui-react';

import { LogoTextBlue, LogoBlue } from 'dyl-components'; 
import TopNavigation from './TopNavigation';

import './index.scss';
import { SETTINGS, SETTINGS_ROOT } from 'shared/constants/SETTINGS';
import { useSelector } from 'react-redux';

const SettingsPage = () => {

    const [isSidebarOpen, setSidebarOpen] = useState(true);

    const img = isSidebarOpen
        ? { src: LogoTextBlue}
        : { src: LogoBlue};
    
    const isAuthenticatingUser = useSelector(state => state.auth.isAuthenticatingUser);
    const authenticateUserMessage = useSelector(state => state.auth.authenticateUserMessage);
        if (isAuthenticatingUser) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        )
    }
    if ((!isAuthenticatingUser && authenticateUserMessage !== 'success')) {
        return <Navigate to="/login" />;
    }

    return (
        <React.Fragment>
            <TopNavigation isSidebarOpen={isSidebarOpen} setSidebarOpen={() => { setSidebarOpen(!isSidebarOpen) }} />
            <Sidebar.Pushable as={Menu} attached className='SettingsPage__SidebarPushable'>
                <Sidebar id="settingsSidebar" as={Menu} inverted visible={true} vertical borderless className={`SettingsPage__Sidebar SettingsPage__Sidebar${isSidebarOpen ? '--full' : '--squished'}`} >
                    <Menu.Item as={Link} to="/" className={`Sidebar__MenuItem`}>
                        <Image src={img.src} size={img.size} className='MenuItem__DYL2Logo' />
                    </Menu.Item>
                    {SETTINGS.map(({ path, name, icon, description, child }) => (
                        <Menu.Item
                            active={false}
                            as={NavLink}
                            to={`/${SETTINGS_ROOT}${path ? `/${path}` : '/'}${child ? `/${child}` : ''}`}
                            className={`Sidebar__MenuItem Sidebar__MenuItem${isSidebarOpen ? '--full' : '--squished'}`}
                        >
                            {isSidebarOpen ?
                                <div className='MenuItem__Container'>
                                    <div className='MenuItem__Icon'>
                                        <Icon className={icon} />
                                    </div>
                                    <div className='MenuItem__Name'>
                                        <Header as='h4'>
                                            {name}
                                            <Header.Subheader>
                                                {description}
                                            </Header.Subheader>
                                        </Header>
                                    </div>
                                </div>
                                : <Icon className={icon} />}
                        </Menu.Item>
                    ))}
                </Sidebar>
                <Sidebar.Pusher className={`SettingsPage__SidebarPusher SettingsPage__SidebarPusher${isSidebarOpen ? '--full' : '--squished'}`} style={{ overflow: 'visible' }}>
                    <Segment basic className={`SidebarPusher__Segment SidebarPusher__Segment${isSidebarOpen ? '--full' : '--squished'}`}>
                        <Outlet />
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </React.Fragment>
    )
};

export default SettingsPage;
