export default {
    CALL_REQUEST: 'CALL_REQUEST',
    CALL_SUCCESS: 'CALL_SUCCESS',
    CALL_FAILURE: 'CALL_FAILURE',

    HANGUP_REQUEST: 'HANGUP_REQUEST',
    HANGUP_SUCCESS: 'HANGUP_SUCCESS',
    HANGUP_FAILURE: 'HANGUP_FAILURE',

    TRANSFER_REQUEST: 'TRANSFER_REQUEST',
    TRANSFER_SUCCESS: 'TRANSFER_SUCCESS',
    TRANSFER_FAILURE: 'TRANSFER_FAILURE',

    RESOLVE_CALL_RESULT: 'RESOLVE_CALL_RESULT',

    CONNECT_CALL: 'CONNECT_CALL',
    NEW_CALL: 'NEW_CALL',
    END_CALL: 'END_CALL',
}
