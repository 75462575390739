import productActions from "actions/product";
import { Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import { Error404, Error500 } from "pages/ErrorPages";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import ProductForm from "shared/forms/ProductForm";
import { MODEL_SCHEDULE } from "shared/schemas/products/MODEL_SCHEDULE";
import { formatProductDetails, getDefaultValuesFromReadingAProduct } from "shared/schemas/products/helper";
import productSchema from "shared/schemas/products/productSchema";

const EditProduct = ({ id }) => {
    const { product, productBeingUpdated } = useSelector(state => state.products);
    const { price_data } = product;
    const [model, schedule] = MODEL_SCHEDULE[price_data.model].split(":");

    const methods = useForm({
        mode: "onChange",
        defaultValues: getDefaultValuesFromReadingAProduct({
            ...product,
            model,
            schedule
        }),
        resolver: generateResolver(productSchema)
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSave = async (data) => {
        try {
            await dispatch(productActions.updateProduct(id, formatProductDetails(data)));
            Notification.alert("Succesfully updated product", STATUS_TYPES.SUCCESS);
            methods.reset();
            navigate("/settings/product-catalog-quotes-invoices/product-catalog", { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update product", STATUS_TYPES.ERROR);
        }
    }

    return (
        <FormProvider {...({ ...methods, productBeingEdited: id })}>
            <ProductForm onSave={onSave} id={id} loading={productBeingUpdated} />
        </FormProvider>
    )
}

const EditProductContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productActions.readProduct(id))
    }, [id, dispatch]);

    const { productBeingRead, productError, product } = useSelector(state => state.products);

    if (productBeingRead) {
        return <Dimmer active><Loader active /></Dimmer>
    }

    if (!productBeingRead && productError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!productBeingRead && !product) {
        return <Error404 message={"Product not found"} />;
    }

    return (
        <EditProduct id={id} />
    )
}

export default EditProductContainer;
