import React, { useContext, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { ModulesFilter } from 'dyl-components';

import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { KEY_ACCOUNT_CO_OWNER, KEY_ACCOUNT_NAME, KEY_ACCOUNT_OPPORTUNITY_STATUS, KEY_ACCOUNT_OWNER, KEY_CONTACT_CO_OWNER, KEY_CONTACT_NAME, KEY_CONTACT_OWNER, KEY_CREATED, KEY_CREATED_BY, KEY_EXPIRES_ON, KEY_LAST_MODIFIED, KEY_LAST_MODIFIED_BY, KEY_NUMBER_PRODUCTS_BUNDLES, KEY_OPPORTUNITY_NAME, KEY_PRODUCTS_BUNDLES, KEY_PROPOSAL_INVOICE_CREATED, KEY_STATUS, KEY_SUBTOTAL } from 'shared/constants/QUOTES_ORDERS_COLUMNS';

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED, label: "Created" },
        { value: KEY_NUMBER_PRODUCTS_BUNDLES, label: "# Products/Bundles" },
        { value: KEY_ACCOUNT_NAME, label: "Account Name" },
    ],
    [
        { value: KEY_CREATED_BY, label: "Created By" },
        { value: KEY_PRODUCTS_BUNDLES, label: "Products/Bundles" },
        { value: KEY_OPPORTUNITY_NAME, label: "Opportunity Name" },
    ],
    [
        { value: KEY_LAST_MODIFIED, label: "Last Modified" },
        { value: KEY_CONTACT_OWNER, label: "Contact Owner" },
        { value: KEY_ACCOUNT_OPPORTUNITY_STATUS, label: "Account/Opportunity Status" },
    ],
    [
        { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
        { value: KEY_CONTACT_CO_OWNER, label: "Contact Co-Owner" },
        { value: KEY_SUBTOTAL, label: "Subtotal" },
    ],
    [
        { value: KEY_PROPOSAL_INVOICE_CREATED, label: "Proposal/Invoice Created" },
        { value: KEY_ACCOUNT_OWNER, label: "Account Owner" },
        { value: KEY_STATUS, label: "Status" },
    ],
    [
        { value: KEY_EXPIRES_ON, label: "Expires On" },
        { value: KEY_ACCOUNT_CO_OWNER, label: "Account Co-Owner" },
    ],
    [
        { value: KEY_EXPIRES_ON, label: "ID", isForcedChecked: true },
        { value: KEY_CONTACT_NAME, label: "Contact Name", isForcedChecked: true },
    ]
];

const QuotesAndOrdersToolbar = ({ isColumnHidden, onToggleColumn }) => {
    const [selectedQuotesAndOrders, , areQuotesAndOrdersInAllPagesSelected] = useContext(BulkActionsContext);

    const hasSelectedQuotesOrOrders = selectedQuotesAndOrders.length || areQuotesAndOrdersInAllPagesSelected;

    const [, setIsBulkActionsModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    return (
        <Grid className='QuotesAndOrdersToolbar'>
            <Grid.Row columns='equal' verticalAlign='middle'>
                <Grid.Column style={{ marginLeft: "-2.75em" }} width={1}>
                    <ModulesFilter
                        hasSearchBar={false}
                        columnRows={COLUMN_ROWS}
                        columnCount={3}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                    />
                </Grid.Column>
                <Grid.Column />
                <Grid.Column width={3} floated='right'>
                    <Button floated='right' className='QuotesAndOrdersToolbar__QuotesAndOrdersButton' disabled={!hasSelectedQuotesOrOrders} onClick={onOpenBulkActionsModal}>
                        Bulk Action
                    </Button>
                </Grid.Column>
                <Grid.Column width={1} />
            </Grid.Row>
        </Grid>
    )
}

export default QuotesAndOrdersToolbar;
