import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Icon } from "semantic-ui-react";
import {
    ButtonLink,
    ClippedContent,
    DateTimeUtils,
    Person,
    Table,
} from "dyl-components";

import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import {
    KEY_ACCOUNT_CO_OWNER,
    KEY_ACCOUNT_NAME,
    KEY_ACCOUNT_OPPORTUNITY_STATUS,
    KEY_ACCOUNT_OWNER,
    KEY_CONTACT_CO_OWNER,
    KEY_CONTACT_NAME,
    KEY_CONTACT_OWNER,
    KEY_CREATED,
    KEY_CREATED_BY,
    KEY_EXPIRES_ON,
    KEY_LAST_MODIFIED,
    KEY_LAST_MODIFIED_BY,
    KEY_NUMBER_PRODUCTS_BUNDLES,
    KEY_OPPORTUNITY_NAME,
    KEY_PRODUCTS_BUNDLES,
    KEY_PROPOSAL_INVOICE_CREATED,
    KEY_STATUS,
    KEY_SUBTOTAL,
} from "shared/constants/QUOTES_ORDERS_COLUMNS";
import HoverableIconWithList from "shared/HoverableIconWithList";
import Contact from "shared/Contact";
import ModuleLink from "shared/ModuleLink";
import { StringUtils } from "utils";

const QuotesAndOrdersRow = ({ entry, isColumnHidden }) => {
    const { type, id: entry_id, contact, account, opportunity } = entry;

    const [selectedEntries, setSelectedEntries] =
        useContext(BulkActionsContext);

    const isSelected = (entry_id) => {
        return selectedEntries.includes(entry_id);
    };

    const onToggleContact = (entry_id) => {
        const newSelectedContacts = isSelected(entry_id)
            ? selectedEntries.filter((id) => entry_id !== id)
            : [...selectedEntries, entry_id];
        setSelectedEntries(newSelectedContacts);
    };

    const key = `${type}-${entry_id}`;

    const formatStatus = (status) => {
        const statusSplit = status.split('/');

        if (statusSplit[0] !== 'rejected') {
            return StringUtils.capitalize(statusSplit[0]);
        } else {
            const rejectReason = statusSplit[1].split('-');
            return `${StringUtils.capitalize(statusSplit[0])}/${rejectReason[0] === 'price' ? 'Priced' : StringUtils.capitalize(rejectReason[0])} ${rejectReason.slice(1, rejectReason.length).join(' ')}`;
        }
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(key)}
                    onChange={() => {
                        onToggleContact(key);
                    }}
                />
            </Table.Cell>
            {!isColumnHidden(KEY_CREATED) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                entry.created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            entry.created,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.created_by}
                        email={entry.created_by_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                entry.last_modified,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            entry.last_modified,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.last_modified_by}
                        email={entry.last_modified_by_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_PROPOSAL_INVOICE_CREATED) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                entry.document_created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            entry.document_created,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_EXPIRES_ON) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <div>
                        <b>
                            {entry.expires &&
                                DateTimeUtils.formatEpoch(
                                    entry.expires,
                                    DateTimeUtils.WORD_DATE_FORMAT
                                )}
                        </b>
                    </div>
                </Table.Cell>
            )}
            <Table.Cell
                className="QuotesAndOrdersTable__MediumFixedWidth"
                width={2}
            >
                <div>
                    <strong>{type}</strong>
                </div>
                <div>
                    <ButtonLink noPadding>{entry_id}</ButtonLink>
                </div>
            </Table.Cell>
            {!isColumnHidden(KEY_NUMBER_PRODUCTS_BUNDLES) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    {entry.number_products_bundles}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_PRODUCTS_BUNDLES) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <HoverableIconWithList
                        icon={
                            <Icon className="fa-solid fa-box" color="primary" />
                        }
                        iconContent={"Products"}
                        items={entry?.products}
                    />
                    <HoverableIconWithList
                        icon={
                            <Icon
                                className="fa-solid fa-boxes-stacked"
                                color="primary"
                            />
                        }
                        iconContent={"Bundles"}
                        items={entry?.bundles}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CONTACT_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.contact_owner}
                        email={entry.contact_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CONTACT_CO_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.contact_co_owner}
                        email={entry.contact_co_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.account_owner}
                        email={entry.account_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_CO_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.account_co_owner}
                        email={entry.account_co_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CONTACT_NAME) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={4}
                >
                    <Contact
                        contact={{
                            ...contact,
                            email: contact?.primary_email,
                            phone: contact?.primary_phone || {},
                        }}
                        name={`${contact.first_name} ${contact.last_name}`}
                        jobTitle={contact.job_title || ""}
                        linkToContact={
                            <Link
                                to={`/contact/${contact.id}`}
                                target="_blank"
                            >{`${contact.first_name} ${contact.last_name}`}</Link>
                        }
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_NAME) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__LargeFixedWidth"
                    width={4}
                >
                    {account?.id && (
                        <Contact
                            contact={{
                                ...account,
                                email: account?.primary_email,
                                phone: account?.primary_phone || {},
                            }}
                            name={account.name}
                            jobTitle={account.job_title || ""}
                            linkToContact={
                                <Link
                                    to={`/account/${account.id}`}
                                    target="_blank"
                                >
                                    {account.name}
                                </Link>
                            }
                        />
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_OPPORTUNITY_NAME) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    {opportunity?.id && (
                        <>
                            <div style={{ width: 170 }}>
                                <ClippedContent>
                                    {opportunity.pipeline_name}
                                </ClippedContent>
                            </div>
                            <div>
                                <ModuleLink pipeline_id={opportunity.pipeline_id} includeLabel unlinked={opportunity.unlinked} />
                            </div>
                        </>
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_OPPORTUNITY_STATUS) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    {opportunity?.id ? (
                        opportunity.outcome !== '' ?
                        <h5 className={`QuotesAndOrdersTable__Outcome${opportunity.outcome.split('(')[0] === 'won' ? '--won' : '--lost'}`}>
                            {opportunity.outcome.charAt(0).toUpperCase() + opportunity.outcome.slice(1).replace('(', ' (')}
                        </h5> : ''
                    ) : (
                        Boolean(account?.id) && (
                            <h5>
                                {account?.account_status}
                            </h5>
                        )
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_SUBTOTAL) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    {entry.subtotal &&
                        `$${StringUtils.formatDecimal(String(entry.subtotal), true)}`}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_STATUS) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    {formatStatus(entry.status)}
                </Table.Cell>
            )}
        </Table.Row>
    );
};

export default QuotesAndOrdersRow;
