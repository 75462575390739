import React, { useContext } from 'react'; 
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { Button, Icon } from 'semantic-ui-react';
import '../index.scss';

const Toolbar = () => {
    const [selectedDevices, , areDevicesInAllPagesSelected] = useContext(BulkActionsContext);
    const hasSelectedDevices = selectedDevices.length || areDevicesInAllPagesSelected;

    return (
        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
            <div>
                <Button style={{marginRight: 10}} className='DevicesToolbar__button' disabled={!hasSelectedDevices} onClick={() => {}}>
                    Bulk Actions
                </Button>
                <Button className='DevicesToolbar__button'>
                    <Icon name='plus' />
                    New
                </Button>
            </div>
        </div>

    )
}

export default Toolbar;
