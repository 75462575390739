import React from 'react';
import { Grid, Header, Icon, Transition } from 'semantic-ui-react';
import { ButtonLink, Panel } from 'dyl-components';
import DepartmentsContent from './Content';
import teamsActions from 'actions/teams';
import { connect } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';
import callHistoryActions from 'actions/call_history';

const DepartmentsHeader = ({
    onToggleDetails,
    isDetailsOpen
}) => (
    <Grid columns='equal'>
        <Grid.Column floated='left'>
            <Header as='h3'>Teams</Header>
        </Grid.Column>
        <Grid.Column floated='right' textAlign='right'>
            <ButtonLink onClick={onToggleDetails}>
                <span><Icon name={`caret ${isDetailsOpen ? 'down' : 'up'}`} /></span>
            </ButtonLink>
        </Grid.Column>
    </Grid>
);

class Departments extends React.Component {
    state = {
        isDetailsOpen: true
    }

    componentDidMount() {
        // this.props.onReadDomains().then(() => {
        //     const { domain } = this.props;
        //     this.props.onReadExtensions(domain);
        //     this.props.onReadTeams().then(() => {
        //         this.props.onReadActiveCalls();
        //         const intervalId = setInterval(this.props.onReadActiveCalls, 15000);
        //         this.setState({ intervalId });
        //     });
        // });


    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    onToggleDetails = () => {
        this.setState(prevState => ({
            isDetailsOpen: !prevState.isDetailsOpen
        }))
    }

    render() {
        return (
            <React.Fragment>
                <Panel
                    header={(
                        <DepartmentsHeader isDetailsOpen={this.state.isDetailsOpen} onToggleDetails={this.onToggleDetails} />
                    )}
                    body={(
                        <Transition visible={this.state.isDetailsOpen} unmountOnHide animation={'slide down'} duration={500}>
                            <DepartmentsContent />
                        </Transition>
                    )}
                    isBodyPadded={false}
                    loading={this.props.isReadingTeams}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    domain: state.pbx_config.domain,
    isReadingTeams: state.teams.isReadingTeams
});

const mapDispatchToProps = dispatch => ({
    onReadTeams: () => {
        return dispatch(teamsActions.getTeams());
    },
    onReadDomains: () => {
        return dispatch(pbxConfigActions.getDomains());
    },
    onReadExtensions: (domain) => {
        dispatch(pbxConfigActions.getExtensions(domain));
    },
    onReadActiveCalls: () => {
        dispatch(callHistoryActions.getActiveCalls())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
