import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ClippedContent,
    DateTimeUtils,
    Person,
    Table,
    TableLoader,
} from "dyl-components";

import {
    KEY_CREATED,
    KEY_NEXT_STEP,
    KEY_CREATED_BY,
    KEY_OPPORTUNITY_AGE,
    KEY_LAST_MODIFIED,
    KEY_LAST_MODIFIED_BY,
    KEY_CONFIDENCE_LEVEL,
    KEY_STAGE,
    KEY_CLOSE_PROBABILITY,
    KEY_OUTCOME,
    KEY_EXPECTED_CLOSE_DATE,
    KEY_LOST_REASON,
    KEY_NEXT_STEP_DATE,
} from "shared/constants/MODULE_COLUMNS";

import { Header, Table as SemanticTable } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import Contact from "shared/Contact";
import ModuleLink from "shared/ModuleLink";

import quoteActions from "actions/quote";

import { StringUtils } from "utils";

import QuoteID from "./QuoteID";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";

const TableCellComponent =
    (outcome) =>
        ({ children, allowDisable = true }) => {
            const isClosed = outcome !== "";
            return (
                <Table.Cell {...(allowDisable ? { disabled: isClosed } : {})}>
                    {children}
                </Table.Cell>
            );
        };

const SubrowContent = ({ quotes }) => {
    const { onEditQuote, onViewProposal } = useContext(QuoteBuilderContext);

    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={12}>
                <div className="ModulesTable__OpportunityQuotesTable">
                    <SemanticTable className="" striped={false}>
                        <SemanticTable.Header>
                            <SemanticTable.Row>
                                <SemanticTable.HeaderCell>
                                    Created On
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Expires On
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Created By
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Last Modified By
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell className="OpportunityQuotesTable__QuoteIDColumn">
                                    Quote ID
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Status
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    # Products/Bundles
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Subtotal
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    One-Time Amount
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Recurring Amount
                                </SemanticTable.HeaderCell>
                            </SemanticTable.Row>
                        </SemanticTable.Header>
                        <SemanticTable.Body>
                            {quotes.map((quote) => {
                                return (
                                    <SemanticTable.Row>
                                        <SemanticTable.Cell>
                                            <div>
                                                {DateTimeUtils.formatEpoch(
                                                    quote.created,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                )}
                                            </div>
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            <div>
                                                {quote.expires && (DateTimeUtils.formatEpoch(
                                                    quote.expires,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                ))}
                                            </div>
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            <Person
                                                username={quote.created_by}
                                                email={quote.created_by_email}
                                            />
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            <Person
                                                username={
                                                    quote.last_modified_by
                                                }
                                                email={
                                                    quote.last_modified_by_email
                                                }
                                            />
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            <QuoteID
                                                hasPdf={Boolean(quote.pdf_id)}
                                                onViewProposal={() => {
                                                    onViewProposal(
                                                        quote.pdf_id
                                                    );
                                                }}
                                                onEditQuote={() => {
                                                    onEditQuote(quote.quote_id);
                                                }}
                                                id={quote.quote_id}
                                            />
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            {StringUtils.capitalize(
                                                quote.quote_status
                                            )}
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            {quote.product_count}
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            {quote.one_time_amount &&
                                                `$${StringUtils.formatDecimal(String(quote.subtotal), true)}`}
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            {quote.one_time_amount &&
                                                `$${StringUtils.formatDecimal(String(quote.one_time_amount), true)}`}
                                        </SemanticTable.Cell>
                                        <SemanticTable.Cell>
                                            {quote.recurring_amount &&
                                                `$${StringUtils.formatDecimal(String(quote.recurring_amount), true)}`}
                                        </SemanticTable.Cell>
                                    </SemanticTable.Row>
                                );
                            })}
                        </SemanticTable.Body>
                    </SemanticTable>
                </div>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
};

const OpportunityRow = ({
    isColumnHidden,
    opportunity
}) => {
    const dispatch = useDispatch();

    const {
        contact,
        account,
        outcome,
        close_reason,
        pipeline_stage_summary,
        pipeline_custom_data,
        pipeline_id,
    } = opportunity;
    const { isReading: isReadingQuotes, quotes } = useSelector(
        (state) =>
            state.quote.opportunityQuotes[pipeline_id] || {
                isReading: false,
                quotes: [],
            }
    );
    const [collapsed, setCollapsed] = useState(true);

    const { fields } = pipeline_custom_data || {};

    const refreshQuotes = async () => {
        dispatch(quoteActions.getQuotesByOpportunityTabView(pipeline_id));
    };

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (collapsed) {
            refreshQuotes();
        }
    };
    
    const TableCell = TableCellComponent(outcome || "");

    const contact_id = Number(useParams().contact_id || contact?.id);

    const { account_id } = useParams();

    const primary_contact_id = useSelector(state => {
        if (account_id) {
            return state.account.account?.primary_contact?.id;
        }
        return account?.primary_person_id;
    });

    const isLinkedToCustomer = useSelector(state => {
        if (account_id) {
            return state.account.account?.customer?.customer_status === "active";
        }
        return account?.customer;
    })

    return (
        <>
            <Table.CollapsibleRow
                onToggle={onToggle}
                collapsed={collapsed}
                toggleDisabled={!opportunity.has_quotes}
                subrowContent={
                    isReadingQuotes ? (
                        <TableLoader colspan={10} isLoading />
                    ) : (
                        <SubrowContent
                            quotes={quotes}
                        />
                    )
                }
            >
                {!isColumnHidden(KEY_CREATED) && (
                    <TableCell>
                        <div>
                            <b>
                                {DateTimeUtils.formatEpoch(
                                    opportunity.pipeline_created,
                                    DateTimeUtils.WORD_DATE_FORMAT
                                )}
                            </b>
                        </div>
                        <div>
                            {DateTimeUtils.formatEpoch(
                                opportunity.pipeline_created,
                                DateTimeUtils.TIME_FORMAT
                            )}
                        </div>
                    </TableCell>
                )}
                {!isColumnHidden(KEY_CREATED_BY) && <TableCell></TableCell>}
                {!isColumnHidden(KEY_LAST_MODIFIED) && (
                    <TableCell>
                        <div>
                            <b>
                                {opportunity.pipeline_activity &&
                                    DateTimeUtils.formatEpoch(
                                        opportunity.pipeline_activity,
                                        DateTimeUtils.WORD_DATE_FORMAT
                                    )}
                            </b>
                        </div>
                        <div>
                            {opportunity.pipeline_activity &&
                                DateTimeUtils.formatEpoch(
                                    opportunity.pipeline_activity,
                                    DateTimeUtils.TIME_FORMAT
                                )}
                        </div>
                    </TableCell>
                )}
                {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                    <TableCell></TableCell>
                )}
                <TableCell allowDisable={false}>
                    {pipeline_id && <ModuleLink pipeline_id={pipeline_id} />}
                </TableCell>
                <TableCell allowDisable={false}>
                    <div style={{ width: 170 }}>
                        <ClippedContent>
                            {opportunity.pipeline_name}
                        </ClippedContent>
                    </div>
                </TableCell>
                {account_id && (
                    <TableCell width={4}>
                        <Contact
                            contact={{
                                ...contact,
                                email: contact?.primary_email,
                                phone: contact?.primary_phone || {},
                            }}
                            name={`${contact.first_name} ${contact.last_name}`}
                            jobTitle={contact.job_title || ""}
                            linkToContact={
                                <Link
                                    to={`/contact/${contact.id}`}
                                >{`${contact.first_name} ${contact.last_name}`}</Link>
                            }
                        />
                    </TableCell>
                )}
                {!isColumnHidden(KEY_OUTCOME) && (
                    <TableCell>
                        {outcome && (
                            <span
                                className={`ModuleRow__outcome--${outcome === "lost" ? "lost" : "won"
                                    }`}
                            >
                                <b>
                                    {outcome.charAt(0).toUpperCase() +
                                        outcome.slice(1).replace("(", " (")}
                                </b>
                            </span>
                        )}
                    </TableCell>
                )}
                {!isColumnHidden(KEY_STAGE) && (
                    <TableCell>
                        <Header as="h5" disabled={outcome}>
                            {pipeline_stage_summary?.pipeline_category_name}
                            <Header.Subheader>
                                {pipeline_stage_summary?.pipeline_stage_name}
                            </Header.Subheader>
                        </Header>
                    </TableCell>
                )}
                {!isColumnHidden(KEY_LOST_REASON) && (
                    <TableCell>
                        {close_reason?.id && <span>{close_reason?.name}</span>}
                    </TableCell>
                )}
                {!isColumnHidden(KEY_EXPECTED_CLOSE_DATE) && (
                    <TableCell></TableCell>
                )}
                {!isColumnHidden(KEY_CONFIDENCE_LEVEL) && (
                    <TableCell>{fields?.confidence_level}</TableCell>
                )}
                {!isColumnHidden(KEY_OPPORTUNITY_AGE) && (
                    <TableCell></TableCell>
                )}
                {!isColumnHidden(KEY_NEXT_STEP_DATE) && <TableCell></TableCell>}
                {!isColumnHidden(KEY_NEXT_STEP) && <TableCell></TableCell>}
                {!isColumnHidden(KEY_CLOSE_PROBABILITY) && (
                    <TableCell>{fields?.close_probability}</TableCell>
                )}
            </Table.CollapsibleRow>
            <QuoteBuilderModal
                account_id={Number(useParams().account_id || account?.id)}
                contact_id={contact_id}
                opportunity_id={pipeline_id}
                account_primary_contact_id={primary_contact_id}
                isLinkedToCustomer={isLinkedToCustomer}
            />
        </>
    );
};

export default OpportunityRow;
