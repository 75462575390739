import types from "./types";
import routes from "actions/routes";

import { Notification, STATUS_TYPES } from 'dyl-components';
import { generateCreateActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

const call = generateCreateActionCreator(routes.API_CALL, ACTION_NAMES.CALL, () => {}, () => {
    Notification.alert('Call failed', STATUS_TYPES.ERROR, true);
});
const hangup = generateCreateActionCreator(routes.API_HANGUP, ACTION_NAMES.HANGUP, () => {}, () => {
    Notification.alert('Failed to hangup', STATUS_TYPES.ERROR, true);
});
const transfer = generateCreateActionCreator(routes.API_TRANSFER, ACTION_NAMES.TRANSFER, () => {}, () => {
    Notification.alert('Failed to transfer call', STATUS_TYPES.ERROR, true);
});
const hold = generateCreateActionCreator(routes.API_HOLD, ACTION_NAMES.HOLD, () => {}, () => {
    Notification.alert('Failed to hold', STATUS_TYPES.ERROR, true);
});

const receiveCall = (channel_uuid) => ({
    type: 'RECEIVE_CALL',
    channel_uuid
})

const answerCall = () => ({
    type: 'ANSWER_CALL'
})

function resolveCallResult() {
    return dispatch => {
        dispatch({
            type: types.RESOLVE_CALL_RESULT
        })
    }
}

export default {
    call,
    hangup,
    transfer,
    hold,
    resolveCallResult,
    receiveCall,
    answerCall
}
