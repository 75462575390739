import React from 'react';
import { Grid, Header, Icon, Transition } from 'semantic-ui-react';
import { ButtonLink, Panel } from 'dyl-components';
import { SendFaxModal } from 'shared/modals';
import CallboxContent from './Content';

import './index.scss';

const CallboxHeader = ({ onToggleSendFaxModal, onToggleDetails, isCallBoxOpen }) => (
    <Grid columns='equal' verticalAlign='middle'>
        <Grid.Column className="CallboxHeaderColumn">
            <Header as='h3' className="CallboxHeader">Office View</Header>
        </Grid.Column>
        <Grid.Column>
            <div className="CallboxFaxDiv">
                <ButtonLink onClick={onToggleSendFaxModal} >
                    <span><Icon name='print' />Fax</span>
                </ButtonLink>
                <ButtonLink onClick={onToggleDetails}>
                    <span><Icon name={`caret ${isCallBoxOpen ? 'down' : 'up'}`} /></span>
                </ButtonLink>
            </div>
        </Grid.Column>
    </Grid>
);

class Callbox extends React.Component {
    state = {
        sendFaxModalVisible: false,
        isCallBoxOpen: true
    }

    onToggleSendFaxModal = () => {
        this.setState({ sendFaxModalVisible: !this.state.sendFaxModalVisible });
    }

    onToggleDetails = () => {
        this.setState(prevState => ({
            isCallBoxOpen: !prevState.isCallBoxOpen
        }))
    }

    render() {
        return (
            <React.Fragment>
                <Panel
                    header={(
                        <CallboxHeader onToggleSendFaxModal={this.onToggleSendFaxModal} isCallBoxOpen={this.state.isCallBoxOpen} onToggleDetails={this.onToggleDetails} />
                    )}
                    body={(
                        <Transition visible={this.state.isCallBoxOpen} unmountOnHide animation={'slide down'} duration={200}>
                            <div><CallboxContent phone={this.props.phone} /></div>
                        </Transition>
                    )}
                    isBodyPadded={false}
                />
                <SendFaxModal
                    open={this.state.sendFaxModalVisible}
                    onClose={this.onToggleSendFaxModal}
                />
            </React.Fragment>
        )
    }
}

export default Callbox;
