export const KEY_CREATED = "Created";
export const KEY_CREATED_BY = "Created By";
export const KEY_LAST_MODIFIED = "Last Modified";
export const KEY_LAST_MODIFIED_BY = "Last Modified by";
export const KEY_PROPOSAL_INVOICE_CREATED = "Proposal/Invoice Created";
export const KEY_QUOTE_ORDER_ID = "ID";

export const KEY_EXPIRES_ON = "Expires On";
export const KEY_OWNER = "Owner";
export const KEY_OWNED_BY = "Owned By";
export const KEY_NUMBER_PRODUCTS_BUNDLES = "# Products/Bundles";
export const KEY_PRODUCTS_BUNDLES = "Products/Bundles";
export const KEY_CONTACT_OWNER = "Contact Owner";
export const KEY_CONTACT_CO_OWNER = "Contact Co-Owner";
export const KEY_ACCOUNT_OWNER = "Account Owner";
export const KEY_ACCOUNT_CO_OWNER = "Account Co-Owner";
export const KEY_CONTACT_NAME = "Contact Name";

export const KEY_ACCOUNT_NAME = "Account Name";
export const KEY_OPPORTUNITY_NAME = 'Opportunity Name';
export const KEY_ACCOUNT_OPPORTUNITY_STATUS = "Account/Opportunity Status";
export const KEY_SUBTOTAL = "Subtotal";
export const KEY_STATUS = "Status";
