const initialState = {
    calls: [],
    isReadingCalls: false,
    
    incomingCalls: [],
    recentCalls: [],
    numberOfConferenceRooms: 0,
    departments: [],
    extensions: [],
    queues: [],
    callerInActionsView: null,
    actionsViewVisible: false,
    isActiveCall: false,

    isReadingCreds: false,
    creds: {},
}

export default initialState;
