import {URI, SessionState, Web} from "sip.js";
// Do we want to specify what we are using
const validDigit = {"0":true, "1": true,"2": true,"3": true,"4": true,"5": true,"6": true,"7": true,"8": true,"9": true,"#": true};


// config will be the creds from provision api
// createAudio is a function that creates an audio element used for the call that accepts a string identifier
// audio will be
// {
//      createAudio: function createAudio(string) audio element - Adds audio element with id from string
//      removeAudio: function removeAudio(string) - removes audio element added with string
//      busy : audio element with source /ext/sounds/callwait.mp3
//      ringtone     : audio element with source /ext/sounds/incoming.mp3
//      ringbacktone : audio element with source /ext/sounds/outgoing.mp3
//      dtmfTone     : audio element with source /ext/sounds/dtmf.mp3
//      remote       : audio element for remote audio
// }
// Callbacks optional callbacks
// {
//      holdCall: function (string, bool) function that marks a call to be on hold session id string and whether it is on hold or not
//      callEnded: function (string) function that marks a call over
//      callConnected: function (string) function that marks a call as connected
//      callCreated: function (string) function when a call is initially created 
//      nomedia
// }
export const Phone = (config, callbacks) => {
    const obj = {};
    obj.callbacks = callbacks;
    obj.busy = document.getElementsByClassName("App__callWait")[0];
    obj.ringtone = document.getElementsByClassName("App__ringTone")[0];
    obj.ringbacktone = document.getElementsByClassName("App__ringbackTone")[0];
    obj.dtmfTone = document.getElementsByClassName("App__dtmfTone")[0];
    obj.remoteAudio = document.getElementsByClassName("App__audioRemote")[0];
    obj.createAudio = sid => {
        const container = document.getElementsByClassName("App__audioHolder")[0];
        const a = document.createElement("audio");
        a.className = "Audio__" + sid;
        container.append(a);
        return a;
    };
    obj.removeAudio = sid => {
        const a = document.getElementsByClassName("Audio__" + sid)[0]
        if (a) {
            a.remove();
        }
    };
    obj.config = {
        aor: new URI("sip", config.username, config.domain),
        "autoStop": true,
        delegate: {
            'onCallAnswered':  sess => {
                obj.stopRingTone();
                obj.stopRingbackTone();
            },
            'onCallCreated': sess => {
                if (!sess.incomingInviteRequest) {
                    obj.newSession(sess)
                }
            },
            'onCallHangup': sess => {
                obj.stopRingTone();
                obj.stopRingbackTone();
                obj.removeAudio(sess.id)
                delete obj.Sessions[sess.id];
                if (obj.callbacks.callEnded) {
                    obj.callbacks.callEnded(sess.id)
                }
                if (sess.id === obj.callActiveID) {
                    obj.callActiveID = null;
                }
            },
            'onCallReceived': s => {
                s.direction = 'incoming';
                obj.newSession(s,'incoming');
            },
            'onRegistered': () => {
                console.log("WE ARE REGISTERED");
                if (obj.callbacks.registered) {
                    obj.callbacks.registered()
                }
            },
            'onServerConnect': () => {
                obj.phoneConnected = true
                obj.phone.register();
            },
            'onServerDisconnect': error => {
                obj.callActiveID = null;
                setTimeout(() => {
                    if (!obj.unloading) {
                        if (obj.callbacks.Disconnected) {
                            obj.callbacks?.Disconnected(obj.phoneConnected)
                        }
                    }
                }, 1000);
            },
            'onUnregistered': () => {
                obj.callActiveID = null;
            }
        },
        media: {
            constraints: {audio: true, video: false},
            remote : function(s){
                const audio = obj.createAudio(s.id)
                return {"audio": audio}
            }
        },
        userAgentOptions: {
            // Changes these to use env variables at compile time
            logBuiltinEnabled: true,
            logConfiguration: true,
            //logLevel: "error",
            logLevel: "debug",

            authorizationPassword: config.password,
            authorizationUsername: config.username,
            uri: new URI("sip", config.username, config.domain),

            autoStart: true,
            autoStop: true,
            noAnswerTimeout: 30,
            wsServers       : "wss://" + config.wss_server,
            gracefulShutdown: true,
            reconnectionDelay: 3,
            rtcpMuxPolicy: 'negotiate',
            userAgentString: "DYL WebRTC"
        }
    };
    obj.Sessions = {};
    obj.callActiveID = null;
    obj.Stream       = null;

    obj.startRingTone = oncall => {
        try {
            if (Object.keys(obj.Sessions).length || oncall) {
                obj.busy.play();
            } else {
                obj.ringtone.play();
            }
        } catch (e) {
        }
    };
    obj.stopRingTone = () => {
        try {
            obj.ringtone.pause();
        } catch (e) { }
    };

    obj.startRingbackTone = () => {
        try { obj.ringbacktone.play(); } catch (e) { }
    };

    obj.stopRingbackTone = () => {
        try { obj.ringbacktone.pause(); } catch (e) { }
    };
    obj.newSession = (newSess, dir) => {
        if (!obj.callActiveID) {
            obj.callActiveID = newSess.id;
        }
        if (dir === 'incoming') {
            obj.startRingTone();
        } else {
            obj.startRingbackTone();
        }
        newSess.stateChange.addListener((newState) => {
            switch (newState) {
            case SessionState.Establishing:
                console.log("Establishing", newSess);
                break;
            case SessionState.Established:
                console.log("Established", newSess);
                // ### We need to add call somewhere
                if (obj.callActiveID && obj.callActiveID !== newSess.id) {
                    if (obj.callbacks.holdCall) {
                        obj.callbacks.holdCall(obj.callActiveID, true)
                    }
                    obj.phone.hold(newSess);
                }
                obj.callActiveID = newSess.id;
                if (obj.callbacks.callConnected) {
                    obj.callbacks.callConnected(newSess.id)
                }
                // Session has been established
                break;
            case SessionState.Terminated:
                if (obj.callbacks.callEnded) {
                    obj.callbacks.callEnded(newSess.id)
                }
                break;
            default:
                // ### We need to add call somewhere
                break;
            }
            newSess.delegate = {};
            newSess.delegate.onAccept = () => {
                obj.stopRingTone();
                obj.stopRingbackTone();
                //console.log("Call has been answered")
                // If there is another active call, hold it
            };
            newSess.delegate.onCancel = () => {
                obj.stopRingTone();
                obj.stopRingbackTone();
                if (dir === 'outgoing') {
                    if (obj.callActiveID === newSess.id) {
                        obj.callActiveID = null;
                    }
                }
                delete obj.Sessions[newSess.id]
            };
            newSess.delegate.onBye = (req) => {
                req.accept()
                obj.stopRingTone();
                obj.stopRingbackTone();
                delete obj.Sessions[newSess.id]
                obj.callActiveID = null;
            };
            if (obj.callbacks.callCreated) {
                obj.callbacks.callCreated(obj.callCreatedArgs(newSess));
            }
        });
        obj.Sessions[newSess.id] = newSess;
    };
    obj.CallCreatedArgs = session => {
        let direction, id, name, phone, stat, calltype;
        if (session.incomingInviteRequest) {
            direction = "inbound";
            id = session.incomingInviteRequest?.message?.callId;
            name = session.incomingInviteRequest?.message?.from['_displayName'];
            phone = session.incomingInviteRequest?.message?.from?.uri?.normal?.user;
            if (name.substr(0,7) === 'Dialing') {
                calltype = "Dialer"
                direction = "outbound";
            } else if (name.substr(0,2) === 'L-') {
                calltype = "Agent"
                direction = "outbound";
            }
        } else if (session.outgoingInviteRequest) {
            direction = "outbound";
            id = session.outgoingInviteRequest?.message?.callId || session.outgoingRequestMessage?.message?.callId;
            name = session.outgoingInviteRequest?.message?.from?.displayName;
            phone = session.outgoingRequestMessage?.to?.uri?.user;
        }
        if (!id) {
            id = session.id.substr(0,20)
        }
        const displayName = session.displayName;
        return { direction, id, name, phone, stat, calltype, displayName };
    };
    obj.getUserMediaFailure = (e) => {
        console.log("getUserMediaFailure failed");
        window.console.error('getUserMedia failed:', e);
        if (obj.callbacks.setError) {
            obj.callbacks.setError(true, 'Media Error.', 'You must allow access to your microphone.  Check the address bar.', true);
        }
    };

    obj.getUserMediaSuccess = (stream) => {
        console.log("getUserMediaFailure success");
        obj.Stream = stream;
    };
    obj.sipCall = (target, contactID, accountID) => {
        try {
            if (target.substring(0,3) !== 'sip') {
                target = 'sip:' +target+'@' + config.domain
            }
            let callOpts = {
                media : {
                    stream      : obj.Stream,
                    constraints : { audio : true, video : false },
                    render      : {
                        remote : obj.remoteAudio
                    },
                    RTCConstraints : { "optional": [{ 'DtlsSrtpKeyAgreement': 'true'} ]}
                },
                extraHeaders: []
            };
            if (contactID) {
                callOpts.extraHeaders.push("X-DYL-ContactID: " + contactID)
            }
            if (accountID) {
                callOpts.extraHeaders.push("X-DYL-AccountID: " + accountID)
            }
            obj.phone.call(target, callOpts);
        } catch(e) {
            throw(e);
        }
    };
    obj.HangUp = () => {
        obj.sipHangUp(obj.callActiveID);
    };
    obj.sipHangUp = (sessionid) => {
        const session = obj.Sessions[sessionid];
        delete obj.Sessions[sessionid];
        if (!session) {
            return;
        }
        switch(session.state) {
          case SessionState.Initial:
            if (session.cancel) {
                // An unestablished outgoing session
                session.cancel();
            } else {
                // An unestablished incoming session
                session.reject();
            }
            break;
          case SessionState.Establishing:
            if (session.cancel) {
                // An unestablished outgoing session
                session.cancel();
            } else {
                // An unestablished incoming session
                session.reject();
            }
            break;
          case SessionState.Established:
            // An established session
            session.bye();
            break;
          case SessionState.Terminating:
          case SessionState.Terminated:
            //delete ctxSip.Sessions[sessionid];
            // Cannot terminate a session that is already terminated
            break;
          default:
        }
    };
    obj.sipSendDTMF = digit => {
        try { 
            obj.dtmfTone.play();
        } catch(e) { }
        const a = obj.callActiveID;
        if (a) {
            const s = obj.Sessions[a];
            if (validDigit[digit]) {
                if (s.sessionDescriptionHandler) {
                    s.sessionDescriptionHandler.sendDtmf(digit)
                }
            }
        }
    };
    /*
    obj.phoneMuteButtonPressed = (sessionid, enable) => {
        const s = obj.Sessions[sessionid];
        if (s) {
            s.sessionDescriptionHandler.enableSenderTracks(enable);
        }
    };
    obj.phoneHoldButtonPressed = (sessionid, enable) => {
        const s = obj.Sessions[sessionid];
        if (s) {
            s.sessionDescriptionHandler.enableSenderTracks(enable);
            s.sessionDescriptionHandler.enableReceiverTracks(enable);
            s.sessionDescriptionHandler.held = !enable;
        }
        if (obj.callbacks.holdCall) {
            obj.callbacks.holdCall(sessionid, enable)
        }
    };
    */
    obj.phoneMuteButtonPressed = (sessionid) => {
        const s = obj.Sessions[sessionid];
        if (s) {
            if (obj.phone.isMuted(s)) {
                obj.phone.unmute(s)
            } else {
                obj.phone.mute(s)
            }
        }
    };
    obj.phoneHoldButtonPressed = (sessionid) => {
        const session = obj.Sessions[sessionid];
        if (session) {
            if (obj.phone.isHeld(session)) {
                obj.phone.unhold(session);
            } else {
                obj.phone.hold(session);
            }
        }
    };
    obj.onHasMedia = () => {
        obj.phone = new Web.SessionManager("wss://" + config.wss_server, obj.config);
        obj.phone.connect();
    };
    obj.onNoMedia = () => {
        if (obj.callbacks.nomedia) {
            obj.callbacks.nomedia()
        }
    };
    obj.hasWebRTC = () => {
        const media = {"audio": true};
        if (navigator.webkitGetUserMedia) {
            navigator.webkitGetUserMedia(media, obj.onHasMedia, obj.onNoMedia)
        } else if (navigator.mozGetUserMedia) {
            navigator.mozGetUserMedia(media, obj.onHasMedia, obj.onNoMedia)
        } else if (navigator.getUserMedia) {
            navigator.getUserMedia(media, obj.onHasMedia, obj.onNoMedia)
        } else if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(media).then(obj.onHasMedia).catch(obj.onNoMedia)
        } else {
            obj.onNoMedia();
        }
    };
    obj.getSession = sip_call_id => {
        return obj.Sessions[sip_call_id]
    };
    obj.checkUnhold = (sip_call_id, ok) => {
        let sess = obj.getSession(sip_call_id);
        if (!sess) {
            return false;
        }
        if (sess.sessionDescriptionHandler.held) {
            if (sess.state !== 'Established' || ok) {
                obj.phoneHoldButtonPressed(sess.id, true);
                return false;
            }
            return true;
        }
        return false;
    }
    obj.hasWebRTC();

    obj.Answer = (sid) => {
        const s = obj.Sessions[sid]
        if (s) {
            try {
                obj.phone.answer(s);
            } catch(e) {
                console.log("Can not answer", e);
            };
        }
    } 

    // START TMP
    // Please do not keep
    // obj.phoneHoldButtonPressed
    obj.tmpHold = () => {
        const s = Object.keys(obj.Sessions)
        if (s.length > 0) {
            try {
                console.log("PRE HOLD", s[0], obj.phone.isHeld(s[0]), obj.Sessions[s[0]], obj.phone.isHeld);
                obj.phoneHoldButtonPressed(s[0])
                console.log("HOLD", s[0]);
            } catch(e) {
                console.log("Can not answer", e);
            };
        }
    }
    obj.tmpMute = () => {
        const s = Object.keys(obj.Sessions)
        if (s.length > 0) {
            try {
                console.log("PRE MUTE", s[0]);
                obj.phoneMuteButtonPressed(s[0])
                console.log("MUTE", s[0]);
            } catch(e) {
                console.log("Can not mute", e);
            };
        }
    }
    obj.tmpAnswer = () => {
        const s = Object.values(obj.Sessions)
        if (s.length > 0) {
            try {
                obj.phone.answer(s[0]);
            } catch(e) {
                console.log("Can not answer", e);
            };
        }
    } 
    // END END TMP
    return obj;
};
