import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CollapsibleList,
    DateTimeUtils,
    GroupedTimeLine,
    Table,
    TableLoader,
} from "dyl-components";

import Avatar from "react-avatar";
import { Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Contact from "shared/Contact";
import { StringUtils } from "utils";
import customerTabActions from "actions/customer_tab";

import CustomerPipelineSubrowHeader from "./CustomerPipelinesSubrowHeader";
import CustomerPipelinesSubrowColumns from "./CustomerPipelineSubrowColumns";

import { ContactContext } from "shared/context/ContactProvider";

const TableCellComponent =
    (outcome) =>
    ({ children, allowDisable = true }) => {
        const isClosed = outcome === "closed";
        return (
            <Table.Cell {...(allowDisable ? { disabled: isClosed } : {})}>
                {children}
            </Table.Cell>
        );
    };

const SubrowContent = ({ logs }) => {
    const getIcon = (entry) => {
        if (entry.action_type === "pipeline") {
            switch (entry.action) {
                case "created":
                    return "fas fa-square-user";
                case "stage_changed":
                case "changed":
                case "closed":
                case "assigned":
                    return "fas fa-funnel-dollar";
                default:
                    return "fas fa-clipboard";
            }
        }
        if (entry.action_type === "sequence") {
            switch (entry.action) {
                case "applied":
                case "changed":
                case "removed":
                    return "fas fa-sitemap";
                default:
                    return "fas fa-clipboard";
            }
        }
        return "fas fa-clipboard";
    };

    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={12}>
                <GroupedTimeLine
                    headers={<CustomerPipelineSubrowHeader />}
                    isLoading={false}
                    additionalColumnNumber={3}
                    useDateFunctions={false}
                    groups={DateTimeUtils.groupDates(
                        (logs || []).map((log) => {
                            return {
                                ts: log.activity,
                                icon: <Icon className={getIcon(log)} />,
                                columns: (
                                    <CustomerPipelinesSubrowColumns
                                        activityHeader={StringUtils.capitalize(
                                            log.action_type
                                        )}
                                        activitySubheader={log.action}
                                        messageNotes={
                                            <div>
                                                {log.message}
                                                {((log.action === "changed" &&
                                                    log.action_type ===
                                                        "pipeline") ||
                                                    log.action ===
                                                        "closed") && (
                                                    <CollapsibleList
                                                        minimumNumberOfItemsToShow={
                                                            0
                                                        }
                                                        items={[
                                                            "",
                                                            "",
                                                            `${
                                                                log.close_reason
                                                                    ? `${
                                                                          log.close_reason
                                                                      }${
                                                                          log.close_type
                                                                              ? `/${log.close_type}`
                                                                              : ""
                                                                      }`
                                                                    : ""
                                                            }`,
                                                            "",
                                                            log.note || "",
                                                        ]}
                                                        action="View"
                                                    />
                                                )}
                                            </div>
                                        }
                                        user={
                                            <Avatar
                                                name={log.user}
                                                round
                                                size="3em"
                                                maxInitials={2}
                                            />
                                        }
                                    />
                                ),
                            };
                        })
                    )}
                />
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
};

const CustomerPipelinesRow = ({ pipeline }) => {
    const {
        contact_information: contact,
        status,
        pipeline_category_name,
        pipeline_stage_name,
        id,
    } = pipeline;
    const { isReading: isReadingTimeline, logs } = useSelector(
        (state) =>
            state.customer_tab.pipelineTimelines[id] || {
                isReading: false,
                logs: [],
            }
    );

    const { onOpenModal } = useContext(ContactContext);

    const [collapsed, setCollapsed] = useState(true);

    const dispatch = useDispatch();

    const refreshTimeline = async () => {
        dispatch(customerTabActions.readPipelineTimeline(pipeline.id));
    };

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (collapsed) {
            refreshTimeline();
        }
    };

    const TableCell = TableCellComponent(status || "");

    return (
        <>
            <Table.CollapsibleRow
                onToggle={onToggle}
                collapsed={collapsed}
                toggleDisabled={!Boolean(pipeline.id)}
                subrowContent={
                    isReadingTimeline ? (
                        <TableLoader colspan={10} isLoading />
                    ) : (
                        <SubrowContent logs={logs} />
                    )
                }
            >
                <TableCell>
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                pipeline.created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            pipeline.created,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </TableCell>
                <TableCell width={4}>
                    <Contact
                        contact={{
                            ...contact,
                            email: {
                                id: contact?.email,
                                email: contact?.email,
                            },
                            phone: {
                                id: contact?.phone,
                                phone: contact?.phone,
                            },
                        }}
                        name={`${contact.first_name} ${contact.last_name}`}
                        jobTitle={contact.email}
                        linkToContact={
                            <Link
                                to={`/contact/${contact.id}`}
                            >{`${contact.first_name} ${contact.last_name}`}</Link>
                        }
                        isModalOutside={true}
                        onExteriorModalOpen={(modalType, contact) => {
                            onOpenModal(modalType, contact);
                        }}
                    />
                </TableCell>
                <TableCell>
                    {status && (
                        <span
                            className={`ModuleRow__outcome--${
                                status === "closed" ? "closed" : "open"
                            }`}
                        >
                            <b>{StringUtils.capitalize(status)}</b>
                        </span>
                    )}
                </TableCell>
                <TableCell>
                    <Header as="h5" disabled={status}>
                        {pipeline_category_name}
                        <Header.Subheader>
                            {pipeline_stage_name}
                        </Header.Subheader>
                    </Header>
                </TableCell>
            </Table.CollapsibleRow>
        </>
    );
};

export default CustomerPipelinesRow;
