import React, {useState} from 'react';
import { SearchBar, Button } from "dyl-components";
import { useSearchParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import AddRingGroupModal from 'shared/modals/AddRingGroupModal';
import './index.scss'


const RingGroupCallQueues = () => {

    const [params] = useSearchParams();

    const searchQuery = params.get("search") || "";


    const [filters, setFilters] = useState({
        type: params.get("type")?.split(","),
        status: params.get("order")?.split(","),
        shared: params.get("page")?.split(","),
    });
    const [openAddModal, setOpenAddModal] = useState(false)

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    return <div>
        <div className="RingGroupHeader">
                <SearchBar
                    isSearching={false}
                    search={searchQuery}
                    searchFcn={(value) => {
                        onFilter(null, { name: "search", value: value });
                    }}
                    cancelFcn={() => {
                        onFilter(null, { name: "search", value: null });
                    }}
                    placeholder={"Search by ring group, call queue name, or internal extension"}
                />
                <div className="RingGroupHeader__ButtonGroup">
                    <Button className="RingGroup__NewUserButton" onClick={()=>{setOpenAddModal(openAddModal=>!openAddModal)}}>
                        <Icon name="plus" /> New
                    </Button>
                </div>
            </div>

            <AddRingGroupModal open={openAddModal} setOpen={(state)=>{setOpenAddModal(state)}} close={setOpenAddModal} />
    </div>;
}

export default RingGroupCallQueues;