import React, { useEffect } from 'react';
import { Notification, STATUS_TYPES, generateResolver, yup } from 'dyl-components';
import { Button, Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomGroupUtils } from 'utils';

import { Controller, useForm } from 'react-hook-form';

import customFieldsGroupActions from "actions/custom_fields_group";
import pipelineActions from 'actions/pipeline';
import MasterSecondarySourceOptions from './MasterSecondarySourceOptions';
import campaignsAction from "actions/campaigns";

import sequenceTasksActions from 'actions/sequence_tasks';
import contactSequenceActions from 'actions/contact_sequence';
import sequencesActions from 'actions/sequences';

const ConvertContactForm = ({
    onClose,

    account_id,
    contact_id,

    size,
    refresh,

    onModify
}) => {
    const { close_reason_id, isCreating, group, isReadingCategories, isReadingCustomFields, pipelineCategories, campaignConvertedOptions, isReadingSequenceContactTasks, contactSequenceTasks, selectedSequenceId } = useSelector(state => {
        return {
            isCreating: state.pipeline.isConvertingContact || state.contact_sequence.isHandlingConvert || state.sequences.isAddingContactToSequence,
            isReadingCustomFields: state.custom_fields_group.isReadingModalStandardFieldGroup,
            group: state.custom_fields_group.modal_standard_group,
    
            pipelineCategories: state.pipeline.categories.map(category => ({
                key: category.id,
                value: category.id,
                text: category.name,
                stages: category.stages?.map(stage => ({
                    key: stage.id,
                    value: stage.id,
                    text: stage.name,
                    sequence_id: stage.sequence_id
                })) || []
            })),
            isReadingCategories: state.pipeline.isReadingCategories,
    
            close_reason_id: state.contact.lead?.close_reason?.id,
            campaignConvertedOptions: state.campaigns.campaignConvertedOptions,

            contactSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
            isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
            selectedSequenceId: state.sequence_tasks.selectedSequenceId
        };
    });

    const fields = group?.fields?.data || [];

    const { formState: { isDirty, isValid }, control, setValue, handleSubmit, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            pipeline: null,
            stage: null,
            type: null,
            product_interests: [],
            expected_revenue: '',
            close_probability: '',
            master_source: null,
            secondary_source: null,
            priority: null,
            rating: null,
            score: null,
            acquisition_cost: null,
            additional_cost: null,
            lead_custom_data: []
        },
        resolver: generateResolver({
            pipeline: yup.string().required('This field is required'),
            stage: yup.string().required('This field is required')
        })
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(customFieldsGroupActions.getModalStandardGroup({ group_name: 'lead' }));
        dispatch(pipelineActions.getPipelineCategories({ account_stage: "lead" }));
        dispatch(campaignsAction.readPersonCampaignConverted({ person_id: contact_id, campaign_conversion: "lead" }));
        dispatch(sequenceTasksActions.readContactTasks(contact_id));
    }, [dispatch, contact_id]);

    useEffect(() => {
        onModify(isDirty);
    }, [isDirty, onModify]);

    const [watchedPipeline] = watch(['pipeline']);

    const onCreate = async (data) => {
        const custom_data = {
            fields: CustomGroupUtils.extractLeadDetails(data),
            children: {}
        };

        const { stage, master_source, secondary_source, campaign_converted, pipeline } = data;
        
        const areSequenceTasksCompleted = contactSequenceTasks.length === 0 || contactSequenceTasks.every(task => task.status === 'complete' || task.status === 'canceled');
        const category = pipelineCategories.find(category => category.value === Number(pipeline));
        const newStage = category?.stages.find(categoryStage => categoryStage.value === Number(stage));

        try {
            const canApplyNewSequence = selectedSequenceId ? await dispatch(contactSequenceActions.handleConvert(contact_id)) : true;
            await dispatch(pipelineActions.convertContact({
                account_id,
                contact_id,
                
                custom_data,
                pipeline_stage_id: Number(stage),
                close_reason_id,
                master_source,
                campaign_id: campaign_converted,
                secondary_source_id: secondary_source,

                previous_sequence_complete: areSequenceTasksCompleted,
                sequence_conversion_removed: canApplyNewSequence
            }));
            if (canApplyNewSequence && newStage?.sequence_id) {
                await dispatch(sequencesActions.addToSequence({ contact_id }, null, newStage?.sequence_id));
            }
            Notification.alert('Successfully converted to lead!', STATUS_TYPES.SUCCESS);
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to convert to lead', STATUS_TYPES.ERROR);
        }
    }

    return [
        <Form loading={isCreating || isReadingSequenceContactTasks} size={size} noValidate style={{ maxHeight: "17em", overflowY: "scroll", overflowX: "hidden", paddingRight: "1em", marginBottom: "1em" }}>
            <Form.Group widths='equal'>
                <Controller
                    name='pipeline'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Pipeline'
                            required
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => {
                                onChange({ target: { name, value } });
                                setValue('stage', pipelineCategories.find(category => category.value === value)?.stages[0]?.value || null, { shouldValidate: true });
                            }}
                            options={pipelineCategories}
                            search
                            selectOnBlur={false}
                            placeholder='Select Pipeline'
                            loading={isReadingCategories}
                        />
                    )}
                />
                <Controller
                    name='stage'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Stage'
                            required
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={pipelineCategories.find(category => category.value === watchedPipeline)?.stages || []}
                            search
                            selectOnBlur={false}
                            placeholder='Select Stage'
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Controller
                    name='type'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Type'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={CustomGroupUtils.getFieldOptions(fields, name)}
                            search
                            selectOnBlur={false}
                            placeholder='Select Type'
                            clearable
                            loading={isReadingCustomFields}
                        />
                    )}
                />
                <Controller
                    name='product_interests'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Product Interests'
                            multiple
                            search
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={[]}
                            selectOnBlur={false}
                            placeholder='Select Product Interest(s)'
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Controller
                    name='expected_revenue'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Expected Revenue'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Expected Revenue'
                        />
                    )}
                />
                <Controller
                    name='close_probability'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Close Probability'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Close Probability'
                        />
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Controller
                    name='master_source'
                    control={control}
                    render={({ field: { name: masterSourceFieldName, value: masterSourceFieldValue, onChange: masterSourceOnChange } }) => (
                        <Controller
                            name='secondary_source'
                            control={control}
                            render={({ field: { name: secondarySourceFieldName, value: secondarySourceValue, onChange: secondarySourceOnChange } }) => (
                                <Form.Field
                                    control={MasterSecondarySourceOptions}
                                    onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                        masterSourceOnChange({ target: { name: masterSourceFieldName, value: master_source } });
                                        secondarySourceOnChange({ target: { name: secondarySourceFieldName, value: secondary_source } });
                                    }}
                                    value={secondarySourceValue}
                                    parentValue={masterSourceFieldValue}
                                    label='Master/Secondary Source'
                                    placeholder='Select Source'
                                    width={8}
                                />
                            )}
                        />
                    )}
                />
                <Controller
                    name={'campaign_converted'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            fluid
                            label='Campaign Converted'
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            options={campaignConvertedOptions.map((campaign) => ({key: campaign.id, value: campaign.id, text: campaign.name}))}
                            search
                            selectOnBlur={false}
                            placeholder='Select Campaign'
                            width={8}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Controller
                    name='priority'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Priority'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={CustomGroupUtils.getFieldOptions(fields, name)}
                            search
                            selectOnBlur={false}
                            placeholder='Select Lead Priority'
                            clearable
                            loading={isReadingCustomFields}
                        />
                    )}
                />
                <Controller
                    name='rating'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            label='Rating'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            options={CustomGroupUtils.getFieldOptions(fields, name)}
                            search
                            selectOnBlur={false}
                            placeholder='Select Lead Rating'
                            clearable
                            loading={isReadingCustomFields}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Controller
                    name='score'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Score'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Lead Score'
                        />
                    )}
                />
                <Controller
                    name='acquisition_cost'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Acquisition Cost'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Acquisition Cost'
                        />
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Controller
                    name='additional_cost'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Input
                            label='Additional Costs'
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder='Enter Additional Costs'
                        />
                    )}
                />
            </Form.Group>
        </Form>,
        <Button floated='right' disabled={!isDirty || !isValid || isCreating} primary onClick={handleSubmit(onCreate)} loading={isCreating}>Save</Button>
    ]
}

export default ConvertContactForm;
