import React from 'react';
import { Grid, Button, Header, List, Ref, } from 'semantic-ui-react';
import { connect } from 'react-redux';

import RecentCalls from './RecentCalls';
import DialPad from './DialPad';

import './Content.scss';
import { Call } from 'shared/Call';
import ActionsView from './ActionsView';
import { Droppable } from 'react-beautiful-dnd';
import CallActions  from 'actions/call'

const CallboxContent = ({ callerInActionsView, isReading, phone, onHold, call, transfer, hangup }) => (
    <Grid columns='equal' stackable stretched>
        <Droppable droppableId={'CALLBOX'}>
            {provided => (
                <Ref innerRef={provided.innerRef}>
                    <Grid.Column width={11} {...provided.droppableProps}>
                        <div className="CallboxContentDiv">
                            {callerInActionsView && (
                                <List divided relaxed>
                                    <Call call={callerInActionsView} draggable={callerInActionsView.uuid_call !== ''} index={0} isReading={isReading} />
                                </List>
                            )}
                            <Button fluid icon='phone' color={'red'} onClick={phone.current.HangUp}/><br/>
                            <Button fluid color={'blue'} onClick={phone.current.tmpAnswer}>Answer</Button><br/>
                            <Button fluid color={'purple'} onClick={phone.current.tmpHold}>Hold</Button><br/>
                            <Button fluid color={'brown'} onClick={phone.current.tmpMute}>Mute</Button><br/>
                            <Button fluid color={'orange'} onClick={function(){
                                console.log("STATE CALL", call);
                                return onHold(call.currentPBX, call.userLeg);
                            }} >Hold</Button><br/>
                            <Button fluid color={'yellow'} onClick={function(){
                                return transfer(call.currentPBX, call.contactLeg, call.currentCall);
                            }} >TRANSFER TO VM</Button><br/>
                            <Button fluid icon='phone' color={'red'} onClick={function(){
                                return hangup(call.currentPBX, call.userLeg)
                            }}>FREESWITCH HANGUP</Button><br/>
                            <RecentCalls />
                        </div>
                        {provided.placeholder}
                    </Grid.Column>
                </Ref>
            )}
        </Droppable>
        <Grid.Column>
            <ActionsView />
            <div className="CallboxDialDiv">
                <Header as='h3'>Dial Pad</Header>
                <div style={{ paddingBottom: 30 }}>
                    <DialPad phone={phone}/>
                </div>
            </div>
        </Grid.Column>
    </Grid>
);

const mapStateToProps = state => {
    console.log("STATE.OFFICEVIEW", state.officeView)
    return {
        callerInActionsView: state.officeView.callerInActionsView,
        actionsViewVisible: state.officeView.actionsViewVisible,
        isActiveCall: state.officeView.isActiveCall,
        recentCalls: state.officeView.recentCalls,
        isReading: state.pbx_config.isReadingCallQueues || state.teams.isReadingTeams,
        //currentPBX: state.officeView.current_pbx,
        call:  state.officeView,
        //contactLeg: state.officeView.contact_leg,
        //userLeg: state.officeView.user_leg
    }
}
const mapDispatchToProps = dispatch => ({
    hangup: (pbx_hostname, channel_uuid) => {
        return dispatch(CallActions.hangup({
          pbx_hostname,
          channel_uuid,
        }));
    },
    transfer: (pbx_hostname, channel_uuid, call_uuid) => {
        return dispatch(CallActions.transfer({
          pbx_hostname,
          channel_uuid,
          transfer_to: "voicemail_399",
          caller_id_name: "hello"
        }));
    },
    onHold: (pbx_hostname, channel_uuid) => {
        return dispatch(CallActions.hold({
          pbx_hostname,
          channel_uuid,
        }));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CallboxContent);
