import React from 'react';
import { Segment, Button, Grid, Icon, Transition } from 'semantic-ui-react';

import Keypad from './Keypad';
import SearchBar from './SearchBar';
//import { PhoneUtil } from 'utils';
import { ButtonLink } from 'dyl-components';

const DialPad = ({ onToggleDialpad, isDialpadOpen, primary, phoneNumber, onInputNumber, onPressNumberKey, isValidNumber, onClear, onDelete, disabled, onOutboundDial }) => (
    <Segment className={`DialPad ${primary ? 'DialPad--primary' : ''}`}>
        <Grid>
            <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                    <ButtonLink onClick={onToggleDialpad} style={{ float: "right" }}>
                        <span><Icon color="black" name="expand arrows alternate" /></span>
                    </ButtonLink>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{paddingBottom:0, paddingTop:0}}>
                <Grid.Column>
                    <Segment basic>
                        <SearchBar disabled={disabled} primary={primary} onInputNumber={onInputNumber} phoneNumber={phoneNumber} isValidNumber={isValidNumber} />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Transition visible={isDialpadOpen} unmountOnHide animation={'slide down'} duration={200}>
            <Grid>
                <Grid.Row style={{paddingBottom:0, paddingTop:0}}>
                    <Grid.Column>
                        <Segment basic>
                            <Keypad disabled={disabled} onPressNumberKey={onPressNumberKey} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingBottom:0, paddingTop:0}}>
                    <Grid.Column>
                        <Segment basic>
                            <Grid columns='equal' verticalAlign='middle'>
                                <Grid.Column>
                                    <Icon name='remove circle' disabled={disabled} link onClick={onClear} />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Button fluid icon='phone' disabled={disabled} color={isValidNumber ? primary ? 'green' : 'primary' : ''} onClick={onOutboundDial}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Icon name='step backward' disabled={disabled} onClick={onDelete} link />
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Transition>
    </Segment>
);

class DialPadContainer extends React.Component {
    state = {
        phoneNumber: '',
        isDialpadOpen: true
    }

    onToggleDialpad = () => {
        this.setState(prevState => ({
            isDialpadOpen: !prevState.isDialpadOpen
        }))
    }

    onInputNumber = (newNumber) => {
        this.setState({ phoneNumber: newNumber });
    }

    onPressNumberKey = (number) => {
        this.props.phone.current.sipSendDTMF(number)
        this.setState(prevState => ({
            phoneNumber: `${prevState.phoneNumber}${number}`
        }));
    }

    onClear = () => {
        this.setState({ phoneNumber: '' });
    }

    onDelete = () => {
        this.setState(prevState => ({
            phoneNumber: prevState.phoneNumber.slice(0, prevState.phoneNumber.length - 1)
        }))
    }

    onOutboundDial = () => {
        //Reach out to the POST dispatch you want to create here. 
        console.log("|+|Dial Phone #:", this.state.phoneNumber)
        this.props.phone.current.sipCall(this.state.phoneNumber)
    }

    render() {
        //TODO: disabled 'til ready
        return (
            <DialPad
                {...this.props}
                {...this.state}
                onInputNumber={this.onInputNumber}
                onPressNumberKey={this.onPressNumberKey}
                // isValidNumber={PhoneUtil.isValid(this.state.phoneNumber)}
                onClear={this.onClear}
                onDelete={this.onDelete}
                onToggleDialpad={this.onToggleDialpad}
                isDialpadOpen={this.state.isDialpadOpen}
                onOutboundDial={this.onOutboundDial}
                // disabled
            />

        )
    }
}

export default DialPadContainer;
