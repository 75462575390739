import React from 'react';
import { Grid, Icon, Ref } from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import TransferDestination from './TransferDestination';

import './Content.scss';
import { connect } from 'react-redux';

const TransferDestinationsContent = ({ transferDestinations, top, onMouseDown, areActiveCallersVisible, areTransferDestinationsDraggable, onDragEnd }) => (
    <Droppable droppableId='TRANSFER_DESTINATIONS' type='TRANSFER_DESTINATIONS' direction='horizontal'>
        {({ droppableProps, placeholder, innerRef }) => (
            <Ref innerRef={innerRef}>
                <Grid {...droppableProps} stackable className="TransferDestinationsContentGrid">
                    <Grid.Row columns={areTransferDestinationsDraggable ? 8 : 4}>
                        {transferDestinations.map((transferDestination, index) => (
                            <Draggable draggableId={transferDestination.name} index={index} isDragDisabled={!areTransferDestinationsDraggable}>
                                {({ innerRef, dragHandleProps, placeholder, draggableProps }, { isDragging }) => (
                                    <Ref innerRef={innerRef}>
                                        <Grid.Column ref={innerRef} className="TransferDestinationColumn">
                                            <TransferDestination
                                                areActiveCallersVisible={areActiveCallersVisible}
                                                id={transferDestination.id}
                                                type={transferDestination.type}
                                                icon={<Icon color='blue' name={transferDestination.icon} />}
                                                activeCallers={transferDestination.activeCallers}
                                                isDragging={isDragging}
                                                {...draggableProps}
                                                {...dragHandleProps}
                                                {...(areTransferDestinationsDraggable ? { onMouseDown: (e) => { onMouseDown(e, dragHandleProps.onMouseDown) } } : {})}
                                                activeCallersViewDirection={index < (areTransferDestinationsDraggable ? 4 : 2) ? 'left' : 'right'}
                                                {...(isDragging ? { style: { ...draggableProps.style, top } } : {})}
                                            >
                                                {transferDestination.name}
                                            </TransferDestination>
                                            {placeholder}
                                        </Grid.Column>
                                    </Ref>
                                )}
                            </Draggable>
                        ))}
                    </Grid.Row>
                    {placeholder}
                </Grid>
            </Ref>
        )}
    </Droppable>
);

class TransferDestinationsContentContainer extends React.Component {
    state = {
        top: 0
    }

    onMouseDown = (e, dragHandlePropsOnMouseDown) => {
        dragHandlePropsOnMouseDown(e);
        this.setState({ top: e.pageY });
    }

    render() {
        return (
            <TransferDestinationsContent
                {...this.props}
                onMouseDown={this.onMouseDown}
            />
        )
    }
}

const mapStateToProps = state => {

    const call_queues = state.pbx_config?.call_queues?.map((call_queue) => ({ id: call_queue.group_number, name: call_queue.label, icon: 'phone', type: 'call_queue', activeCallers: [] })) || []
    const ring_groups = state.pbx_config?.ring_groups?.map((ring_group) => ({ id: ring_group.id, name: ring_group.label, icon: 'phone', type: 'ring_group', activeCallers: [] })) || []
    
    return ({
        transferDestinations: [...call_queues, ...ring_groups]
    })
};

export default connect(mapStateToProps)(TransferDestinationsContentContainer);
