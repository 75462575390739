// import { TableFromData } from 'dyl-components';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import Row from './Row';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

const Table = ({ onPageChange, onReadCallRoutingRule, onDelete }) => {
    const { callRoutingRulesCount: count, isReadingCallRoutingRules, callRoutingRules } = useSelector((state) => state.pbx_config);
    const [params] = useSearchParams();

    return <RecordsTable
        isTableLoading={isReadingCallRoutingRules}
        isSearchResultEmpty={count === 0 && (params.get('search'))}
        isTableEmpty={count === 0 && !(params.get('search'))}
        tableEmptyMessage='No Call Routing Rules Created'
        TableHeader={<Header count={count} callRoutingRules={callRoutingRules} />}
        TableRows={callRoutingRules.map((callRoutingRule) => <Row callRoutingRule={callRoutingRule} onReadCallRoutingRule={onReadCallRoutingRule} onDelete={onDelete} />)}
        recordsCount={count}
        activePage={params.get('page')}
        onPageChange={onPageChange}
        recordsName='Call Routing Rule'
    />
}

export default Table;