import React from 'react';
import { NotificationsHeader } from './Header';
import { NotificationsRow } from './Row';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';

const NotficationsTable = ({ onFilter, onPageChange, onOpenNotification, subRoute, tab, onToggleSnoozeModal, snoozedOptions, remove, archive, markRead, snooze, readSchedule, isReadingSchedule }) => {
    const { count, isReadingNotificationHub, hubNotifications = [] } = useSelector(state => state.notifications);
    const [params] = useSearchParams();

    return (
        <RecordsTable
            tableBodyClassName='NotificationsTable'
            isTableLoading={isReadingNotificationHub}
            isSearchResultEmpty={count === 0 && (params.get('search'))}
            isTableEmpty={count === 0 && !(params.get('search'))}
            tableEmptyMessage='No Notifications Displayed'
            TableHeader={<NotificationsHeader onFilter={onFilter} subRoute={subRoute} />}
            TableRows={hubNotifications.map(notification => (
                <NotificationsRow 
                    key={notification.id} 
                    notification={notification} 
                    onOpenNotification={onOpenNotification} 
                    tab={tab} 
                    onToggleSnoozeModal={onToggleSnoozeModal} 
                    snoozedOptions={snoozedOptions} 
                    remove={remove} 
                    archive={archive} 
                    markRead={markRead} 
                    snooze={snooze}
                    readSchedule={readSchedule}
                    isReadingSchedule={isReadingSchedule}
                />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Contact'
        />
    )
}

export default NotficationsTable;
