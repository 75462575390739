import React from 'react';
import TransferDestinationsContent from './Content';
import { Grid, Header, Button, Popup, Icon, Transition } from 'semantic-ui-react';
import { ArrayUtils } from 'utils';
import { ButtonLink, Panel } from 'dyl-components';
import { connect } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';

const TransferDestinationsHeader = ({ onToggleDetails, isDetailsOpen, onToggleActiveCallersVisibility, areActiveCallersVisible, onToggleDragFunctionOfTransferDestinations, areTransferDestinationsDraggable }) => (
    <Grid columns='equal' verticalAlign='middle'>
        <Grid.Column floated='left'>
            <Header as='h3' style={{ paddingTop: 13, paddingBottom: 13 }}>Transfer Destinations</Header>
        </Grid.Column>
        <Grid.Column floated='right' textAlign='right'>
            <Popup
                trigger={<Button icon={areActiveCallersVisible ? 'hide' : 'eye'} basic onClick={onToggleActiveCallersVisibility} />}
                content={`${areActiveCallersVisible ? 'Hide' : 'Show'} all active callers`}
                inverted
            />
            <Popup
                trigger={<Button icon={areTransferDestinationsDraggable ? 'save' : 'move'} basic onClick={() => {}} />}
                content={areTransferDestinationsDraggable ? 'Save arrangement' : 'Rearrange'}
                inverted
            />
            <ButtonLink onClick={onToggleDetails}>
                <span><Icon name={`caret ${isDetailsOpen ? 'down' : 'up'}`} /></span>
            </ButtonLink>
        </Grid.Column>
    </Grid>
);

const TransferDestinations = ({ onToggleDetails, isDetailsOpen, onToggleActiveCallersVisibility, areActiveCallersVisible, onToggleDragFunctionOfTransferDestinations, areTransferDestinationsDraggable, onTransferDestinationDragEnd, isReadingCallQueues }) => (
    <Panel
        header={(
            <TransferDestinationsHeader
                areActiveCallersVisible={areActiveCallersVisible}
                onToggleActiveCallersVisibility={onToggleActiveCallersVisibility}
                onToggleDragFunctionOfTransferDestinations={onToggleDragFunctionOfTransferDestinations}
                areTransferDestinationsDraggable={areTransferDestinationsDraggable}
                isDetailsOpen={isDetailsOpen}
                onToggleDetails={onToggleDetails}
            />
        )}
        body={(
            <Transition visible={isDetailsOpen} unmountOnHide animation={'slide down'} duration={500}>
                <TransferDestinationsContent
                    areActiveCallersVisible={areActiveCallersVisible}
                    areTransferDestinationsDraggable={areTransferDestinationsDraggable}
                    onDragEnd={onTransferDestinationDragEnd}
                />
            </Transition>
        )}
        isBodyPadded={false}
        loading={isReadingCallQueues}
    />
);

class TransferDestinationsContainer extends React.Component {
    state = {
        areActiveCallersVisible: false,
        areTransferDestinationsDraggable: false,
        isDetailsOpen: true,
    }

    componentDidMount() {
        // this.props.onReadDomains().then(() => {
        //     const { domain } = this.props;
        //     this.props.onReadCallQueues(domain);
        //     this.props.onReadRingGroups(domain);
        // });
    }

    onToggleDetails = () => {
        this.setState(prevState => ({
            isDetailsOpen: !prevState.isDetailsOpen
        }))
    }

    onToggleActiveCallersVisibility = () => {
        this.setState(prevState => ({
            areActiveCallersVisible: !prevState.areActiveCallersVisible
        }))
    }

    onToggleDragFunctionOfTransferDestinations = () => {
        this.setState(prevState => ({
            areTransferDestinationsDraggable: !prevState.areTransferDestinationsDraggable
        }))
    }

    onShowAllActiveCallers = () => {
        this.setState({ areActiveCallersVisible: true });
    }

    onHideAllActiveCallers = () => {
        this.setState({ areActiveCallersVisible: false });
    }

    onTransferDestinationDragEnd = (result) => {
        const destination = result.destination;
        if (!destination) {
            return;
        }
        const transferDestinations = this.state.transferDestinations.slice(0);
        const source = result.source;
        const reorderedTransferDestinations = ArrayUtils.reorder(transferDestinations, source.index, destination.index);
        this.setState({
            transferDestinations: reorderedTransferDestinations
        });
    }

    render() {
        return (
            <TransferDestinations
                areActiveCallersVisible={this.state.areActiveCallersVisible && this.props.isActive}
                onToggleActiveCallersVisibility={this.onToggleActiveCallersVisibility}
                areTransferDestinationsDraggable={this.state.areTransferDestinationsDraggable}
                onToggleDragFunctionOfTransferDestinations={this.onToggleDragFunctionOfTransferDestinations}
                onTransferDestinationDragEnd={this.onTransferDestinationDragEnd}
                isDetailsOpen={this.state.isDetailsOpen}
                onToggleDetails={this.onToggleDetails}
                isReadingCallQueues={this.props.isReadingCallQueues}
            />
        )
    }
}

const mapStateToProps = state => ({
    domain: state.pbx_config.domain,
    isReadingCallQueues: state.pbx_config.isReadingCallQueues
});

const mapDispatchToProps = dispatch => ({
    onReadCallQueues: (domain) => {
        return dispatch(pbxConfigActions.getCallQueues(domain));;
    },
    onReadDomains: () => {
        return dispatch(pbxConfigActions.getDomains());
    },
    onReadRingGroups: (domain) => {
        return dispatch(pbxConfigActions.getRingGroups(domain));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TransferDestinationsContainer);
