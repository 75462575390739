import React from 'react';
import { QuotesAndOrdersHeader } from './Header';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordsTable } from 'dyl-components';
import QuotesAndOrdersRow from './Row';

const QuotesAndOrdersTable = ({ isColumnHidden, onPageChange }) => {
    const { count, quotesAndOrders, isReadingQuotesAndOrders } = useSelector(state => ({
        count: 0,
        quotesAndOrders: [],
        isReadingQuotesAndOrders: false
    }));

    const [params] = useSearchParams();

    return (
        <RecordsTable
            tableBodyClassName='QuotesAndOrdersTable'
            isTableLoading={isReadingQuotesAndOrders}
            isSearchResultEmpty={count === 0 && (params.get('start') || params.get('end'))}
            isTableEmpty={count === 0 && !(params.get('start') || params.get('end'))}
            TableHeader={<QuotesAndOrdersHeader isColumnHidden={isColumnHidden} />}
            TableRows={quotesAndOrders.map(log => (
                <QuotesAndOrdersRow key={log.id} contact={log} isColumnHidden={isColumnHidden} />
            ))}
            recordsCount={count}
            activePage={params.get('page')}
            onPageChange={onPageChange}
            recordsName='Contact'
        />
    )
}

export default QuotesAndOrdersTable;
