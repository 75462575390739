import React, { useCallback, useEffect, useState } from 'react';
import { TableWithHeader } from 'dyl-components';
import Toolbar from './subcomponents/Toolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { useDispatch } from 'react-redux';
import provisionActions from 'actions/provision';
import Table from './subcomponents/Table';
import '../index.scss';

const DEVICES_URL = "/settings/phone-management/devices";

const Devices = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${DEVICES_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const [filters, setFilters] = useState({
        type: params.get('type')?.split(','),
        status: params.get('status')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const onReload = useCallback(() => {
        dispatch(provisionActions.getAllDevices(params));
    }, [dispatch, params])

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        const { type, status } = filters;
        if (type?.length > 0) {
            query.set('type', type.join(','));
        } else {
            query.delete('type');
        }
        if (status?.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }
        const query_string = query.toString();
        navigate(`${DEVICES_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        onReload();
    }, [onReload])

    return <div className='Devices'>
        <TableWithHeader
            header={<Toolbar />}
            table={
                <Table
                    onFilter={onFilter}
                    onPageChange={onPageChange}
                    onOpenModal={() => {}}
                />
            }
        />
    </div>
}

export default () => (
    <BulkActionsProvider>
        <Devices />
    </BulkActionsProvider>
);